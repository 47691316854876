import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeDefaultBrickLimiterAction,
  setSelectedTabAction,
  changeThresholdValueAction,
  changeAttackValueAction,
  changeReleaseValueAction,
  changePregainValueAction,
  changePostgainValueAction,
  setEnableTextChatAction,
  setVideoEnabledAction,
  setAudioEnabledAction,
} from "../Redux/Reducers/Connection";
import LargeVideo from "./LargeVideo";
import {
  getEventDetails,
  updateEventLock,
  getParticipantsService,
} from "../Services/interceptorServices";
// import moment from 'moment';
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
// import axios from '../helpers/axios';

const eventFields = {
  name: "",
  details: "",
  relay_language: "",
  connected_counts_ui: "",
  is_locked: "0",
  timezone: "",
  event_date: "",
  event_time: "",
  interpreter_code: "",
  is_demo: false,
  event_id: "",
  connected_counts: {
    clients: 0,
    interpreters: 0,
    listeners: 0,
    moderators: 0,
    speakers: 0,
  },
  languages: null,
  moderator_code: "",
  enable_video: "0",
  enable_audio: "0",
};

const ParticipantsSideListing = (props) => {
  const {
    booth_language_id,
    event_id,
    selecteduser,
    localTracks,
    remoteTracks,
    selectedTab,
    participant_type,
    thresholdValue,
    enable_audio,
    enable_video,
    displayName,
  } = useSelector(({ connection }) => connection);
  const dispatch = useDispatch();
  const [eventDetail, setEventDetail] = useState(eventFields);
  const [pList, setPlist] = useState(null);
  const [currentBoothInterpreters, setCurrentBoothInterpreters] = useState([]);
  const [otherBoothInterpreters, setOtherBoothInterpreters] = useState([]);
  const [currentTime, setCurrentTime] = useState(
    moment().format("MMM DD, YYYY HH:mm")
  );

  const [track, setTrack] = useState(null);
  const { data, error } = useQuery({
    queryKey: ["todos", event_id],
    queryFn: async () => {
      const participantData = await getParticipantsService(event_id);
      return participantData?.data;
    },
    // Refetch the data every second
    refetchInterval: 2000,
  });

  if (error) {
    toast.dismiss();
    toast.error(error.message);
  }

  useEffect(() => {
    if (data?.code === 200) {
      setCurrentBoothInterpreters([]);
      setOtherBoothInterpreters([]);

      let currentBoothInterpretersList = [];
      let otherBoothInterpretersList = [];
      if (
        data.data &&
        data.data.interpreters &&
        data.data.interpreters.length > 0
      ) {
        for (let interpreter of data.data.interpreters) {
          if (interpreter.booth_language_id == booth_language_id) {
            currentBoothInterpretersList.push(interpreter);
          } else {
            otherBoothInterpretersList.push(interpreter);
          }
        }
      }

      setPlist(data.data);
      setCurrentBoothInterpreters(currentBoothInterpretersList);
      setOtherBoothInterpreters(otherBoothInterpretersList);
      props.getCurrentBoothInterpreters(currentBoothInterpretersList);
      props.getOtherBoothInterpreters(otherBoothInterpretersList);
    }
  }, [data]);

  useEffect(() => {
    if (props.eventLockData) {
      let data = JSON.parse(props.eventLockData);

      if (data) {
        setEventDetail({
          ...eventDetail,
          is_locked: data.is_locked || "0",
        });

        if (data.is_locked) {
          toast.warning(`This event is locked by ${data.userName}`);
        } else {
          toast.warning(`This event is unlocked by ${data.userName}`);
        }
      }
    }
  }, [props.eventLockData]);

  useEffect(() => {
    setTrack(null);
    if (selecteduser === "local") {
      setTimeout(() => {
        setTrack(localTracks.find((tk) => tk.getType() === "video"));
      }, 0);
    } else if (selecteduser && remoteTracks[selecteduser]) {
      setTimeout(() => {
        setTrack(
          remoteTracks[selecteduser].find((tk) => tk.getType() === "video")
        );
      }, 0);
    }
  }, [selecteduser, localTracks, remoteTracks]);

  const getEvent = async () => {
    let resp = await getEventDetails(event_id);
    if (resp && resp.data && resp.data.code === 200 && resp.data.data) {
      setEventDetail(resp.data.data);
      props.getEventDetail(resp.data.data);
      props.setApiCalled(true);

      dispatch(
        setEnableTextChatAction(
          resp.data.data.enable_text_chat === "1" ? true : false
        )
      );
      if (enable_video === true) {
        dispatch(
          setVideoEnabledAction(
            resp.data.data.enable_video === "1" ? true : false
          )
        );
      }

      if (enable_audio === true) {
        dispatch(
          setAudioEnabledAction(
            resp.data.data.enable_audio === "1" ? true : false
          )
        );
      }

      let newDefaultSettings = {
        thresholdValue: resp.data.data.default_threshold,
        releaseValue: resp.data.data.default_release_seconds,
        attackValue: resp.data.data.default_attack_seconds,
        pregainValue: resp.data.data.default_pregain_db,
        postgainValue: resp.data.data.default_postgain_db,
      };
      dispatch(
        changeDefaultBrickLimiterAction(JSON.stringify(newDefaultSettings))
      );

      if (!thresholdValue) {
        dispatch(changeThresholdValueAction(newDefaultSettings.thresholdValue));
        dispatch(changeAttackValueAction(newDefaultSettings.attackValue));
        dispatch(changeReleaseValueAction(newDefaultSettings.releaseValue));
        dispatch(changePregainValueAction(newDefaultSettings.pregainValue));
        dispatch(changePostgainValueAction(newDefaultSettings.postgainValue));
      }
    } else {
      props.setApiCalled(false);
      // setEventDetail(eventFields);
      // props.getEventDetail(eventFields);
      localStorage.clear();
      window.location.reload();
    }
  };

  const actionEventLock = async (eventId, isLock) => {
    updateEventLock(eventId, isLock)
      .then((response) => {
        setEventDetail({
          ...eventDetail,
          is_locked: isLock,
        });
        if (isLock) {
          toast.success("This event is now locked");
        } else {
          toast.success("Event is now unlocked");
        }

        const room = window.APP?.room;
        if (room) {
          room.setLocalParticipantProperty(
            "isEventLock",
            JSON.stringify({
              userName: displayName,
              is_locked: isLock,
            })
          );
        }
      })
      .catch((err) => {
        console.log("***ERROR***", err);
      });
  };

  useEffect(() => {
    getEvent();

    const timer = setInterval(() => {
      setCurrentTime(moment().format("MMM DD, YYYY HH:mm"));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // const fetchParticipantAPIResponse = useCallback(() => {
  //   if (event_id) {
  //     getParticipantsService(event_id)
  //       .then((resp) => {
  //         if (resp && resp.data && resp.data.code === 200) {
  //           setCurrentBoothInterpreters([]);
  //           setOtherBoothInterpreters([]);

  //           let currentBoothInterpretersList = [];
  //           let otherBoothInterpretersList = [];
  //           if (
  //             resp.data.data &&
  //             resp.data.data.interpreters &&
  //             resp.data.data.interpreters.length > 0
  //           ) {
  //             for (let interpreter of resp.data.data.interpreters) {
  //               if (interpreter.booth_language_id == booth_language_id) {
  //                 currentBoothInterpretersList.push(interpreter);
  //               } else {
  //                 otherBoothInterpretersList.push(interpreter);
  //               }
  //             }
  //           }

  //           setPlist(resp.data.data);
  //           setCurrentBoothInterpreters(currentBoothInterpretersList);
  //           setOtherBoothInterpreters(otherBoothInterpretersList);
  //           props.getCurrentBoothInterpreters(currentBoothInterpretersList);
  //           props.getOtherBoothInterpreters(otherBoothInterpretersList);
  //         }
  //       })
  //       .catch((err) => console.log("***ERR***", err));
  //   }
  // }, []);

  // if (event_id) {
  // setInterval(() => fetchParticipantAPIResponse(), 2000);

  // const data = useQuery({
  //   queryKey: ["event", event_id],
  //   queryFn: getParticipantsService(event_id)
  // })

  // console.log("data>", data);

  // }

  // useEffect(() => {
  //   if (event_id) {
  //     // setInterval(() => fetchParticipantAPIResponse(), 2000);
  //     // const data = useQuery({
  //     //   queryKey: ["event", event_id],
  //     //   queryFn: getParticipantsService(event_id)
  //     // })
  //     // console.log("data>", data);
  //   }
  // }, [event_id, selectedTab, Object.keys(joinedUsers).length, props.refresh]);

  return (
    <div
      className={`card ${
        participant_type === "moderator" ? "card-dark-outline" : ""
      }                     
      ${selectedTab === 2 ? "video-tab-height" : "auto-video-height"}         
      `}
    >
      <ul className="nav nav-fill nav-pills p-2" role="tablist">
        {/* Details */}
        <li
          className="nav-item"
          title="Details"
          onClick={() => dispatch(setSelectedTabAction(0))}
        >
          <a
            className={`nav-link py-1 ${selectedTab === 0 ? "active" : ""}`}
            id="details-tab"
            data-toggle="tab"
            href="#details"
            role="tab"
            aria-controls="details"
            aria-selected="true"
          >
            Details
          </a>
        </li>
        {/* Participant */}
        <li
          className="nav-item"
          title="Participants"
          onClick={() => dispatch(setSelectedTabAction(1))}
        >
          <a
            className={`nav-link py-1 ${selectedTab === 1 ? "active" : ""}`}
            id="participants-tab"
            data-toggle="tab"
            href="#participants"
            role="tab"
            aria-controls="participants"
            aria-selected="true"
          >
            Participants
          </a>
        </li>
        {/* Video */}
        {participant_type === "interpreter" ? (
          <li
            className="nav-item"
            title="Video"
            onClick={() => dispatch(setSelectedTabAction(2))}
          >
            <a
              className={`nav-link py-1 ${selectedTab === 2 ? "active" : ""}`}
              id="video-tab"
              data-toggle="tab"
              href="#video"
              role="tab"
              aria-controls="video"
              aria-selected="false"
            >
              Video
            </a>
          </li>
        ) : null}
      </ul>

      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              selectedTab === 0 ? "active show" : ""
            }`}
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <div id="event-details">
              {participant_type === "client" && (
                <div>
                  <h5>{eventDetail.name}</h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: eventDetail.details }}
                  />
                </div>
              )}
              {participant_type !== "client" && (
                <table className="table table-sm">
                  <tbody>
                    {participant_type === "interpreter" && (
                      <tr>
                        <td colSpan="2">
                          <b>{eventDetail.name}</b>
                        </td>
                      </tr>
                    )}
                    {participant_type === "moderator" && (
                      <tr>
                        <td className={"interface-tr"}>Interfaces:</td>
                        <td className={"interface-tr"}>
                          <div className="hint" id="event-interfaces-hint">
                            Interfaces configured for the event. These can be
                            audio, video, or text chats between groups of
                            participants.
                          </div>
                          <span
                            className="mr-2"
                            data-toggle="tooltip"
                            title="Video is available"
                            data-original-title="Video is available"
                          >
                            <i className="fa-regular fas fa-video"></i>
                          </span>
                          <span
                            className="mr-2"
                            data-toggle="tooltip"
                            title="Audio is available"
                            data-original-title="Audio is available"
                          >
                            <i className="fa-regular fas fa-volume-up"></i>
                          </span>
                          <span
                            data-toggle="tooltip"
                            title="Text chat is available"
                            data-original-title="Text chat is available"
                          >
                            <i className="fa-regular fa-comment"></i>
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Relay:</td>
                      <td>{eventDetail.relay_language}</td>
                    </tr>
                    <tr>
                      <td>Connected:</td>
                      <td>
                        <div className="float-left">
                          {eventDetail.connected_counts && (
                            <React.Fragment>
                              <div>
                                {eventDetail.connected_counts.interpreters}{" "}
                                Interpreter
                              </div>
                              <div>
                                {eventDetail.connected_counts.clients} Client
                              </div>
                              <div>
                                {eventDetail.connected_counts.moderators}{" "}
                                Technician
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                        <div className="float-right">
                          <a
                            role="button"
                            className="btn btn-xs btn-light border"
                            style={{ width: "2rem", padding: "2px 5px" }}
                            data-toggle="tooltip"
                            title={
                              Number(eventDetail.is_locked)
                                ? "Unlock Event"
                                : "Lock Event"
                            }
                            onClick={() => {
                              if (
                                window.confirm(
                                  Number(eventDetail.is_locked)
                                    ? "Others participants will now be able to join."
                                    : "No other participants will be able to join."
                                ) == true
                              ) {
                                actionEventLock(
                                  eventDetail.event_id,
                                  Number(eventDetail.is_locked) === 0 ? 1 : 0
                                );
                              }
                            }}
                          >
                            <i
                              className={`fa-solid fa-lock${
                                Number(eventDetail.is_locked)
                                  ? " text-danger"
                                  : "-open text-dark"
                              } `}
                            ></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              <table className="table table-sm">
                <tbody>
                  {participant_type !== "moderator" && (
                    <tr>
                      <td colSpan="2" className="bg-light small font-italic">
                        <i className="fa-regular fa-clock mr-1"></i>
                        <span className="text-muted">
                          Times are in 24-hour format
                        </span>
                      </td>
                    </tr>
                  )}
                  {/* Start time of event  */}
                  <tr>
                    <td>
                      <div>Start time:</div>
                      <div className="text-muted small">Event's time zone</div>
                    </td>
                    <td>
                      <div>
                        {eventDetail?.event_date && eventDetail?.event_time
                          ? moment(
                              eventDetail.event_date +
                                " " +
                                eventDetail.event_time
                            )
                              .parseZone()
                              .format("MMM DD, YYYY HH:mm")
                          : "-"}
                      </div>
                      <div className="text-muted small">
                        {eventDetail?.event_date && eventDetail?.timezone ? (
                          <div>
                            {eventDetail.timezone} GMT
                            {moment(
                              eventDetail.event_date +
                                " " +
                                eventDetail.event_time
                            )
                              .tz(eventDetail.timezone)
                              .format("Z")}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                  </tr>
                  {/* start time computer time zone */}
                  <tr>
                    <td>
                      <div>Start time:</div>
                      <div className="text-muted small">
                        Computer's time zone
                      </div>
                    </td>
                    <td>
                      <div>
                        {eventDetail?.event_date && eventDetail?.event_time
                          ? moment
                              .tz(
                                eventDetail.event_date +
                                  " " +
                                  eventDetail.event_time,
                                eventDetail.timezone
                              )
                              .tz(
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                              )
                              .format("MMM DD, YYYY HH:mm")
                          : "-"}
                      </div>
                      <div className="text-muted small">
                        {eventDetail?.event_date && eventDetail.event_time ? (
                          <div>
                            {Intl.DateTimeFormat().resolvedOptions().timeZone}{" "}
                            GMT
                            {moment(
                              eventDetail.event_date +
                                " " +
                                eventDetail.event_time
                            ).format("Z")}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                  </tr>
                  {/* Current time of event */}
                  <tr>
                    <td>
                      <div>Current time:</div>
                      <div className="text-muted small">Event's time zone</div>
                    </td>
                    <td>
                      <div>
                        {eventDetail?.timezone
                          ? moment()
                              .tz(eventDetail?.timezone)
                              .format("MMM DD, YYYY HH:mm")
                          : "-"}
                      </div>
                      <div className="text-muted small">
                        {eventDetail?.timezone && eventDetail?.event_date ? (
                          <div>
                            {eventDetail.timezone} GMT
                            {moment(
                              eventDetail.event_date +
                                " " +
                                eventDetail.event_time
                            )
                              .tz(eventDetail.timezone)
                              .format("Z")}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                  </tr>
                  {/* Current time, computer time zone */}
                  <tr>
                    <td>
                      <div>Current time:</div>
                      <div className="text-muted small">
                        Computer's time zone
                      </div>
                    </td>
                    <td>
                      <span id="local-time">{currentTime}</span>
                      <div className="text-muted small">
                        <div>
                          {Intl.DateTimeFormat().resolvedOptions().timeZone} GMT
                          {moment().format("Z")}
                        </div>
                      </div>
                    </td>
                  </tr>
                  {/* Duration */}
                  <tr>
                    <td>Duration:</td>
                    <td>{eventDetail.duration ? eventDetail.duration : "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            className={`tab-pane fade ${
              selectedTab === 1 ? "active show" : ""
            }`}
            id="participants"
            role="tabpanel"
            aria-labelledby="participants-tab"
          >
            <div id="participants-pane">
              <ul className="nav nav-tabs nav-fill" role="tablist">
                {participant_type === "interpreter" ? (
                  <li className="nav-item" title="Interpreters">
                    <a
                      className="nav-link py-1 active"
                      id="interpreters-tab"
                      data-toggle="tab"
                      href="#interpreters"
                      role="tab"
                      aria-controls="interpreters"
                      aria-selected="true"
                    >
                      Interpreters
                    </a>
                  </li>
                ) : null}

                <li className="nav-item" title="Clients">
                  <a
                    className={`nav-link py-1 ${
                      participant_type !== "interpreter" ? "active" : ""
                    }`}
                    id="clients-tab"
                    data-toggle="tab"
                    href="#clients"
                    role="tab"
                    aria-controls="clients"
                    aria-selected={participant_type !== "interpreter"}
                  >
                    Clients
                  </a>
                </li>
                <li className="nav-item" title="Video">
                  <a
                    className="nav-link py-1"
                    id="moderators-tab"
                    data-toggle="tab"
                    href="#moderators"
                    role="tab"
                    aria-controls="moderators"
                    aria-selected="false"
                  >
                    Technicians
                  </a>
                </li>
              </ul>

              <div className="tab-content mt-3">
                <div
                  className="tab-pane table-list fade show active"
                  id="interpreters"
                  role="tabpanel"
                  aria-labelledby="interpreters-tab"
                >
                  {pList &&
                    pList.interpreters &&
                    pList.interpreters.length > 0 && (
                      <table className="table table-sm table-striped">
                        <tbody>
                          {currentBoothInterpreters.map(
                            (interpreter, index) => {
                              return (
                                <tr key={index}>
                                  <td>{interpreter.name}</td>
                                  <td className="text-right">
                                    <i className="fa-regular fa-star mr-1"></i>
                                    {interpreter.booth_language_name}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          {otherBoothInterpreters.map((interpreter, index) => {
                            return (
                              <tr key={index}>
                                <td>{interpreter.name}</td>
                                <td className="text-right">
                                  <i className="fa-regular fa-star mr-1"></i>
                                  {interpreter.booth_language_name}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  {!pList ||
                    (pList &&
                      pList.interpreters &&
                      pList.interpreters.length === 0 && (
                        <div className="text-center">
                          No interpreters have joined yet.
                        </div>
                      ))}
                  {/* {Object.keys(joinedUsers).length === 0 &&
                                        <div className="text-center">No interpreters have joined yet.</div>
                                    } */}
                </div>
                <div
                  className="tab-pane table-list fade"
                  id="clients"
                  role="tabpanel"
                  aria-labelledby="clients-tab"
                >
                  {!pList ||
                    (pList && pList.clients && pList.clients.length === 0 && (
                      <div className="text-center">
                        No clients have joined yet.
                      </div>
                    ))}
                  {pList && pList.clients && pList.clients.length > 0 && (
                    <table className="table table-sm table-striped">
                      <tbody>
                        {pList.clients.map((client, index) => {
                          return (
                            <tr key={index}>
                              <td>{client.name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
                <div
                  className="tab-pane table-list fade"
                  id="moderators"
                  role="tabpanel"
                  aria-labelledby="moderators-tab"
                >
                  {!pList ||
                    (pList &&
                      pList.moderators &&
                      pList.moderators.length === 0 && (
                        <div className="text-center">
                          No technicians have joined yet.
                        </div>
                      ))}

                  {pList && pList.moderators && pList.moderators.length > 0 && (
                    <table className="table table-sm table-striped">
                      <tbody>
                        {pList.moderators.map((client, index) => {
                          return (
                            <tr key={index}>
                              <td>{client.name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              selectedTab === 2 ? "active show" : ""
            }`}
            id="video"
            role="tabpanel"
            aria-labelledby="video-tab"
          >
            <div className="text-center main-video-wrapper">
              {track && <LargeVideo track={track} />}
              {!track && (
                <div
                  id="video-viewer-instruction"
                  className="font-italic text-muted p-3"
                  style={{ display: "block" }}
                >
                  View a larger version of an interpreter's video by clicking
                  their thumbnail.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default memo(ParticipantsSideListing);
export default ParticipantsSideListing;
