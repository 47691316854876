import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function InterpreterTrack({ index, track, jitsiPid, pId }) {
  const [audioElementState, setAudioElementState] = useState(null);
  const {
    boothmatesMute,
    brickLimiter,
    thresholdValue,
    attackValue,
    releaseValue,
    pregainValue,
    postgainValue,
    allAudioVolumeForMod,
    audioOutputDeviceId,
  } = useSelector((state) => state.connection);

  const attachAudio = useCallback(
    async (refData) => {
      if (refData && window.APP.audioContext) {            
        refData.muted = true;
        track.attach(refData);

        let audioCtx = window.APP.audioContext;

        const source = audioCtx.createMediaStreamSource(track.stream);
        const dest = audioCtx.createMediaStreamDestination();

        if (brickLimiter) {
          const limiter = window.APP.limiter;

          source.connect(limiter);
          limiter.connect(dest);
                 
          limiter.isReady.then(() => {
            //Atack
            limiter.attack.setValueAtTime(attackValue, audioCtx.currentTime); // set value 100ms in 10 second

            //Release
            limiter.release.setValueAtTime(releaseValue, audioCtx.currentTime);

            //Threshold
            limiter.threshold.setValueAtTime(
              thresholdValue,
              audioCtx.currentTime
            );

            //Pregain
            limiter.preGain.setValueAtTime(pregainValue, audioCtx.currentTime);

            //Postgain
            limiter.postGain.setValueAtTime(
              postgainValue,
              audioCtx.currentTime
            );
          });
        } else {
          source.connect(dest);
        }
        let audioElement = document.getElementById(
          `${jitsiPid}-audio-${pId}_process`
        );
        audioElement.srcObject = dest.stream;
        if (!audioElementState) {
          setAudioElementState(audioElement);
        }
      }
    },
    [
      track,
      jitsiPid,
      pId,
      audioElementState,
      brickLimiter,
      thresholdValue,
      attackValue,
      releaseValue,
      pregainValue,
      postgainValue,
    ]
  );

  useEffect(() => {
    let audioElement = document.getElementById(
      `${jitsiPid}-audio-${pId}_process`
    );
    if (audioElement && audioElementState) {
      if (audioElement.setSinkId) {
        audioElement.setSinkId(audioOutputDeviceId);
      }
      audioElement.muted = boothmatesMute ? true : false;
      audioElement.volume = allAudioVolumeForMod;
      audioElement.play();
    }
  }, [
    jitsiPid,
    pId,
    audioElementState,
    allAudioVolumeForMod,
    boothmatesMute,
    audioOutputDeviceId,
  ]);

  return (
    <>
      <audio autoPlay="1" ref={attachAudio} id={`${jitsiPid}-audio-${pId}`} />
      <audio autoPlay="1" id={`${jitsiPid}-audio-${pId}_process`} />
    </>
  );
  // if (track.getType() === "video") {
  //   if (track.isMuted()) {
  //     return <></>;
  //   }
  //   return <></>;
  // } else {
  //   return (
  //     <>
  //       <audio autoPlay="1" ref={attachAudio} id={`${jitsiPid}-audio-${pId}`} />
  //       <audio autoPlay="1" id={`${jitsiPid}-audio-${pId}_process`} />
  //     </>
  //   );
  // }
}

function compare(preProps, nextProps) {
  return preProps.track.isMuted() !== nextProps.track.isMuted();
}

export default memo(InterpreterTrack, compare);
