/* global JitsiMeetJS */

import React, { memo, useEffect, useState, useCallback } from "react";
import QuickMessages from "./QuickMessages";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../helpers/constant";
import { interpreterUpdateService } from "../Services/interceptorServices";
import { useSelector, useDispatch } from "react-redux";
import {
  setBoothIdAction,
  changeBrickLimiterAction,
  setAudioVideoDeviceIdAction,
  setBoothmatesMuteAction,
  setAllAudioForMod,
  changeInterpreterSortType,
} from "../Redux/Reducers/Connection";
import { capitalizeFirstLetter } from "../helpers/common";
import Meter from "./Meter";
import InterpreterTrack from "./interpreterTrack";
import { useMutation } from "@tanstack/react-query";

function InterpreterListing({
  eventDetail,
  participantData,
  oParticipantData,
  refresh,
  room,
}) {
  const {
    joinedUsers,
    remoteTracksArray,
    booth_language_id,
    boothmatesMute,
    brickLimiter,
    devices,
    audioOutputDeviceId,
    allAudioVolumeForMod,
    interpreterSortListType,
  } = useSelector((state) => state.connection);

  const [interpreterList, setInterpreterList] = useState([]);

  const updateInterpreterData = useMutation({
    mutationFn: async (obj) => {
      return await interpreterUpdateService(obj);
    },
  });

  const [pFormValues, setPFormValues] = useState({
    participant_id: "",
    name: "",
    is_connected: "",
    booth_language_id: "",
  });
  const [name, setName] = useState("");
  const [remoteAudioLevel, setRemoteAudioLevel] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (room) {
      function audioListener(id, value) {
        setRemoteAudioLevel(value);
      }

      room.on(
        JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
        audioListener
      );

      return () => {
        room.off(
          JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
          audioListener
        );
      };
    }
  }, [room]);

  useEffect(() => {
    let list = [...participantData, ...oParticipantData];

    if (interpreterSortListType === "name") {
      let sortByName = list.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setInterpreterList(sortByName);
    } else if (interpreterSortListType === "booth") {
      let sortByBooth = list.sort((a, b) =>
        a.booth_language_name
          ?.toLowerCase()
          .localeCompare(b.booth_language_name?.toLowerCase())
      );
      setInterpreterList(sortByBooth);
    } else {
      setInterpreterList(list);
    }
  }, [interpreterSortListType, participantData, oParticipantData]);

  const getBooths = () => {
    if (eventDetail && eventDetail.languages) {
      let sortedLanguages = Object.values(eventDetail.languages)
        .map((value) => value)
        .sort();

      return sortedLanguages.map((booth, index) => {
        let id = Object.keys(eventDetail.languages).find((key) => {
          return eventDetail.languages[key] === booth;
        });

        return (
          <option key={index} value={id}>
            {booth}
          </option>
        );
      });
    }
    return <></>;
  };

  const updateParticipant = () => {
    let payload = {};
    if (name && name !== pFormValues.name) {
      payload.name = pFormValues.name;
    }

    const toastId = toast.loading("Please wait ...");
    updateInterpreterData.mutate(
      {
        participant_id: pFormValues.participant_id,
        booth_language_id: pFormValues.booth_language_id,
        is_connected: pFormValues.is_connected,
        ...payload,
      },
      {
        onSuccess: (resp) => {
          if (resp && resp.data && resp.data.code === 200) {
            document.getElementById("closeModal").click();
            refresh();
            // toast.success("Interpreter updated successfully!");
            toast.update(toastId, {
              render: "Interpreter updated successfully!",
              type: "success",
              isLoading: false,
              closeButton: true,
              autoClose: 1500,
            });
          } else if (resp && resp.data && resp && resp.data.errors.length) {
            // toast.error(resp.data.errors[0]);
            toast.update(toastId, {
              render: resp.data.errors[0],
              type: "error",
              isLoading: false,
              closeButton: true,
              autoClose: 1500,
            });
          }
        },
        onerror: (err) => {
          // toast.error(err.message || "Something went wrong");
          toast.update(toastId, {
            render: err.message || "Something went wrong",
            type: "error",
            isLoading: false,
            closeButton: true,
            autoClose: 1500,
          });
        },
      }
    );

    // interpreterUpdateService({
    //   participant_id: pFormValues.participant_id,
    //   booth_language_id: pFormValues.booth_language_id,
    //   is_connected: pFormValues.is_connected,
    //   ...payload,
    // })
    //   .then((resp) => {
    //     if (resp && resp.data && resp.data.code === 200) {
    //       document.getElementById("closeModal").click();
    //       refresh();
    //       toast.success("Participant updated successfully!");
    //     } else if (resp && resp.data && resp && resp.data.errors.length) {
    //       toast.error(resp.data.errors[0]);
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error("Something went wrong!");
    //     console.log("***err***", err);
    //   });
  };

  const createTracks = useCallback(
    (jitsiPid, pId) => {
      let existingTrack = remoteTracksArray.find(
        (item) => item.id === jitsiPid
      );

      if (existingTrack && existingTrack.tracks.length > 0) {
        return existingTrack.tracks.map((track, index) =>
          track.getType() === "audio" ? (
            <InterpreterTrack
              key={track.getId()}
              index={index}
              track={track}
              jitsiPid={jitsiPid}
              pId={pId}
            />
          ) : (
            <div key={track.getId()}></div>
          )
        );
      }
    },
    [remoteTracksArray]
  );

  const getStreamData = useCallback(
    (pId) => {
      let jitsiPid = "";
      Object.keys(joinedUsers).map((id, mainIndex) => {
        if (
          joinedUsers[id] &&
          joinedUsers[id]._properties &&
          joinedUsers[id]._properties.participant_type &&
          joinedUsers[id]._properties.participant_type === "interpreter" &&
          joinedUsers[id]._properties.participantId === pId
        ) {
          jitsiPid = id;
        }
      });
      if (jitsiPid) {
        return createTracks(jitsiPid, pId);
      }
    },
    [joinedUsers, createTracks, remoteTracksArray]
  );

  const interPreterRemoteTrack = useCallback(
    (p) => {
      return (
        <td className=" dt-head-center dt-body-center ">
          <a
            data-bs-toggle="modal"
            data-bs-target="#participnatModal"
            onClick={() => {
              setPFormValues({
                participant_id: p.participant_id,
                name: p.name,
                is_connected: p.is_connected,
                booth_language_id: p.booth_language_id,
              });
              setName(p.name);
            }}
          >
            {p.name}
          </a>

          {getStreamData(p.participant_id)}
        </td>
      );
    },
    [getStreamData]
  );

  const getMuteStatus = (pId) => {
    let jitsiPid = "";
    Object.keys(joinedUsers).map((id, mainIndex) => {
      if (
        joinedUsers[id] &&
        joinedUsers[id]._properties &&
        joinedUsers[id]._properties.participant_type &&
        joinedUsers[id]._properties.participant_type === "interpreter" &&
        joinedUsers[id]._properties.participantId === pId
      ) {
        jitsiPid = id;
      }
    });
    let isMuted = true;
    if (jitsiPid) {
      let existingTrack = remoteTracksArray.find(
        (item) => item.id === jitsiPid
      );
      if (existingTrack && existingTrack.tracks.length > 0) {
        existingTrack.tracks.map((track) => {
          if (track.getType() === "audio" && !track.isMuted()) {
            isMuted = false;
          }
        });
      }
    }
    return isMuted;
  };

  const getAudioStatus = (pId) => {
    let audioStatus = false;
    Object.keys(joinedUsers).map((id, mainIndex) => {
      if (         
        joinedUsers[id] &&
        joinedUsers[id]._properties &&
        joinedUsers[id]._properties.participant_type &&
        joinedUsers[id]._properties.participant_type === "interpreter" &&
        joinedUsers[id]._properties.participantId === pId
      ) {
        audioStatus = joinedUsers[id]._properties.boothmatesMute;
      }
    });
    return (
      <i
        className={`fas ${audioStatus ? "fa-volume-mute" : "fa-volume-up"} `}
      ></i>
    );
  };

  function changeBoothLanguage(value) {
    dispatch(setBoothIdAction(value));
    setTimeout(() => window.location.reload(), 100);
  }

  const changeBrickState = () => {
    if (brickLimiter === true) {
      if (
        window.confirm(
          "You are disabling a system that may protect your hearing. Proceed? Yes/No"
        )
      ) {
        dispatch(changeBrickLimiterAction(false));
      }
    } else {
      dispatch(changeBrickLimiterAction(true));
    }
  };

  let configureSwitchProps = {};
  if (brickLimiter === false) {
    configureSwitchProps.disabled = true;
  }

  const renderDeviesList = () => {
    const audioOutputDevices = devices.filter((d) => d.kind === "audiooutput");

    if (audioOutputDevices.length === 0) {
      let odevice = JitsiMeetJS.mediaDevices.getAudioOutputDevice();
      return (
        <option value={odevice}>
          {capitalizeFirstLetter(odevice)} Speaker
        </option>
      );
    }
    return audioOutputDevices.map((d, index) => {
      let extraProps = {};
      if (audioOutputDeviceId === d.deviceId) {
        extraProps.defaultValue = "selected";
      }
      return (
        <option key={index} {...extraProps} value={d.deviceId}>
          {d.label}
        </option>
      );
    });
  };

  const changeBoothmatesMute = () => {
    dispatch(setBoothmatesMuteAction(!boothmatesMute));
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="interpreter-list-wrapper">
            <div className="interpreter-float-left">
              <h5>{eventDetail && eventDetail.name}</h5>
            </div>
            <div className="interpreter-float-right">
              <div className="hint" id="moderator-listening-channel-hint">
                Select a channel through which to listen.
              </div>
              <div className="form-inline interpreter-list-bx">
                {/* Audio device selection */}
                <div>
                  <select
                    name="device"
                    id="audioOutputSelect"
                    value={audioOutputDeviceId}
                    onChange={(e) => {
                      JitsiMeetJS.mediaDevices.setAudioOutputDevice(
                        e.target.value
                      );
                      dispatch(
                        setAudioVideoDeviceIdAction({
                          type: "audiooutput",
                          deviceId: e.target.value,
                        })
                      );
                    }}
                    className="custom-select bg-light border border-dark"
                    defaultValue={audioOutputDeviceId}
                  >
                    {/* <option value="">Choose...</option> */}
                    {renderDeviesList()}
                  </select>
                </div>

                {/* CONFIGURE */}

                <div className="audio-limit-bx">
                  <div className="clearfix">
                    <div className="mt-5- float-left">
                      <div className="form-check form-switch mt-2 pe-auto ">
                        <input
                          className="form-check-input cursor mr-1-"
                          onChange={() => {
                            changeBrickState();
                          }}
                          type="checkbox"
                          role="switch"
                          // {...brickLimiterSwitchProps}
                          checked={brickLimiter}
                          id="brickwallSettings"
                        />
                        <label
                          className="form-check-label mx-2- mr-2 text-center mt-1- "
                          htmlFor="brickwallSettings"
                        >
                          Audio Limiter {brickLimiter ? "ON" : "OFF"}
                        </label>
                      </div>
                    </div>
                    <button
                      type="button"
                      {...configureSwitchProps}
                      className={`mt-5- btn-sm float-left ${
                        configureSwitchProps.disabled === true
                          ? "btn btn-secondary"
                          : "btn btn-primary"
                      }`}
                      data-bs-toggle="modal"
                      data-bs-target="#configureModal"
                    >
                      Configure Limiter
                    </button>
                  </div>
                </div>

                {/* Audio level */}
                <div>
                  <Meter value={!boothmatesMute ? remoteAudioLevel * 200 : 0} />
                </div>

                {/* Audio mute/ unmute  */}
                <div>
                  <button
                    id="mute-boothmates-btn "
                    className={`btn btn-sm btn-block unmute audio-manage-btn mb-3 ${
                      boothmatesMute ? "btn-primary" : "btn-secondary"
                    }`}
                    style={{ height: "4rem" }}
                    onClick={() => {
                      changeBoothmatesMute();
                    }}
                  >
                    <i
                      className={`fas ${
                        boothmatesMute ? "fa-volume-mute" : "fa-volume-up"
                      } fa-xl mr-2`}
                    ></i>
                    {boothmatesMute ? "Audio is OFF" : "Audio is ON"}
                  </button>
                </div>

                {/* volume slider */}
                <div>
                  <div className="chime-volume">
                    <div id="player">
                      <input
                        type="range"
                        min={0.1}
                        max={1}
                        step={0.1}
                        defaultValue={allAudioVolumeForMod}
                        onMouseUp={(e) =>
                          dispatch(setAllAudioForMod(e.target.value))
                        }
                        onTouchEnd={(e) =>
                          dispatch(setAllAudioForMod(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Ear icon */}
                <div>
                  <i
                    className="fa-regular fa-ear fa-lg mr-2"
                    data-toggle="tooltip"
                    title="Listening To"
                  ></i>

                  {/* Booth selection */}

                  <select
                    name="language_id"
                    className="custom-select bg-light border border-dark"
                    value={booth_language_id}
                    onChange={(e) => changeBoothLanguage(e.target.value)}
                  >
                    <option value="0">No interpretation</option>
                    {getBooths()}
                  </select>

                  <span className="link-separator mx-2">|</span>
                  <button
                    className="btn btn-light btn-sm border-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i
                      className="fa-regular fa-ticket"
                      data-toggle="tooltip"
                      title="Invite People"
                    ></i>
                  </button>
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable modal-sm">
                      <div className="modal-content">
                        <div
                          className="modal-header"
                          style={{ position: "relative", display: "flex" }}
                        >
                          <h4 className="modal-title" id="exampleModalLabel">
                            <i className="fa-regular fa-ticket fa-sm mr-2"></i>
                            Invite
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>

                        <div className="modal-body">
                          <div className="modal-body" id="modal-body">
                            <div className="card border pb-3">
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="control-label">
                                    Interpreter code
                                  </label>
                                  <div className="col-sm-12">
                                    <div className="input-group input-group-sm">
                                      <input
                                        type="text"
                                        name="interpreter_code"
                                        id="interpreter-code"
                                        value={
                                          eventDetail &&
                                          eventDetail.interpreter_code
                                        }
                                        readOnly="readonly"
                                        className="form-control form-control-sm text-center bg-light border border-dark"
                                      />
                                      <div className="input-group-append">
                                        {eventDetail && (
                                          <CopyToClipboard
                                            text={
                                              window.location.origin +
                                              "/join/" +
                                              eventDetail.interpreter_code
                                            }
                                            onCopy={() =>
                                              toast.success(
                                                "Code Copied Successfully!"
                                              )
                                            }
                                          >
                                            <button
                                              data-toggle="tooltip"
                                              title=""
                                              className="btn btn-sm btn-info"
                                              data-original-title="Copy to clipboard"
                                            >
                                              <i className="fa-solid fa-link"></i>
                                            </button>
                                          </CopyToClipboard>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="control-label">
                                    Client code
                                  </label>
                                  <div className="col-sm-12">
                                    <div className="input-group input-group-sm">
                                      <input
                                        type="text"
                                        name="client_code"
                                        value={
                                          eventDetail && eventDetail.client_code
                                        }
                                        id="client-code"
                                        readOnly="readonly"
                                        className="form-control form-control-sm text-center bg-light border border-dark"
                                      />
                                      <div className="input-group-append">
                                        {eventDetail && (
                                          <CopyToClipboard
                                            text={
                                              window.location.origin +
                                              "/join/" +
                                              eventDetail.client_code
                                            }
                                            onCopy={() =>
                                              toast.success(
                                                "Code Copied Successfully!"
                                              )
                                            }
                                          >
                                            <button
                                              data-toggle="tooltip"
                                              title="Copy to clipboard"
                                              className="btn btn-sm btn-info"
                                            >
                                              <i className="fa-solid fa-link"></i>
                                            </button>
                                          </CopyToClipboard>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="control-label">
                                    Technician code
                                  </label>
                                  <div className="col-sm-12">
                                    <div className="input-group input-group-sm">
                                      <input
                                        type="text"
                                        name="moderator_code"
                                        value={
                                          eventDetail &&
                                          eventDetail.moderator_code
                                        }
                                        id="moderator-code"
                                        readOnly="readonly"
                                        className="form-control form-control-sm text-center bg-light border border-dark"
                                      />
                                      <div className="input-group-append">
                                        {eventDetail && (
                                          <CopyToClipboard
                                            text={
                                              window.location.origin +
                                              "/join/" +
                                              eventDetail.moderator_code
                                            }
                                            onCopy={() =>
                                              toast.success(
                                                "Code Copied Successfully!"
                                              )
                                            }
                                          >
                                            <button
                                              data-toggle="tooltip"
                                              title="Copy to clipboard"
                                              className="btn btn-sm btn-info"
                                            >
                                              <i className="fa-solid fa-link"></i>
                                            </button>
                                          </CopyToClipboard>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="text-center"
                              style={{ position: "relative", top: "-30px" }}
                            >
                              {eventDetail && (
                                <CopyToClipboard
                                  text={`Interpreter Code - ${window.location.origin}/join/${eventDetail.interpreter_code} Technician Code - ${window.location.origin}/join/${eventDetail.moderator_code} Client Code - ${window.location.origin}/join/${eventDetail.client_code}`}
                                  onCopy={() =>
                                    toast.success("Code Copied Successfully!")
                                  }
                                >
                                  <button className="btn btn-light btn-sm border border-secondary">
                                    Copy All
                                    <i className="fa-solid fa-link ml-2"></i>
                                  </button>
                                </CopyToClipboard>
                              )}

                              <div className="mt-4">
                                <a
                                  href={`${API_BASE_URL}/invite/index/5`}
                                  target="_blank"
                                >
                                  More invitation options...
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2 text-right">
            <QuickMessages />
          </div>
          <div className="filterDropDown">
            <label>Filter: </label>
            <select
              className="custom-select bg-light border border-dark"
              onChange={(e) => {
                dispatch(changeInterpreterSortType(e.target.value));
              }}
              value={interpreterSortListType}
            >
              <option value="">Sort By</option>
              <option value="name">Name (A-Z)</option>
              <option value="booth">Booth name (A-Z)</option>
            </select>
          </div>
          <div id="interpreters-pane" className="table-responsive">
            <div className="table-responsive">
              <div
                id="interpreter_bloc_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <table
                  className="table table-hover table-striped table-bordered table-sm w-100 dataTable no-footer text-center"
                  id="interpreter_bloc"
                  style={{ width: "100%" }}
                  role="grid"
                >
                  <thead>
                    <tr className="bg-table-header" role="row">
                      <th
                        className="dt-head-center dt-body-center sorting"
                        tabIndex="0"
                        aria-controls="interpreter_bloc"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Interpreter Name: activate to sort column ascending"
                        style={{ width: "418.2px" }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                      >
                        {" "}
                        <a> Interpreter Name </a>{" "}
                      </th>
                      <th
                        className="dt-head-center dt-body-center sorting_desc"
                        tabIndex="0"
                        aria-controls="interpreter_bloc"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Booth: activate to sort column ascending"
                        aria-sort="descending"
                        style={{ width: "187.2px" }}
                      >
                        Booth
                      </th>
                      <th
                        className="dt-head-center dt-body-center sorting"
                        tabIndex="0"
                        aria-controls="interpreter_bloc"
                        rowSpan="1"
                        colSpan="1"
                        aria-label=": activate to sort column ascending"
                        style={{ width: "101.2px" }}
                      >
                        <i
                          className="fa-solid fa-message-lines fa-message"
                          data-toggle="tooltip"
                          title="Actively Interpreting"
                          data-original-title="Actively Interpreting"
                        ></i>
                      </th>
                      <th
                        className="dt-head-center dt-body-center sorting"
                        tabIndex="0"
                        aria-controls="interpreter_bloc"
                        rowSpan="1"
                        colSpan="1"
                        aria-label=": activate to sort column ascending"
                        style={{ width: "93.2px" }}
                      >
                        <i
                          className="fa-solid fa-microphone"
                          style={{ font_size: "1rem" }}
                          datatoggle="tooltip"
                          title="Mic Status"
                          dataoriginaltitle="Mic Status"
                        ></i>
                      </th>
                      <th
                        className="dt-head-center dt-body-center sorting"
                        tabIndex="0"
                        aria-controls="interpreter_bloc"
                        rowSpan="1"
                        colSpan="1"
                        aria-label=": activate to sort column ascending"
                        style={{ width: "91.2px" }}
                      >
                        <i
                          className="fa-solid fa-ear"
                          style={{ font_size: "1rem" }}
                          data-toggle="tooltip"
                          title="Audio Status"
                        ></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {interpreterList.length > 0 &&
                      interpreterList.map((p, index) => {
                        return (
                          <tr className="odd" key={`p_${index}`}>
                            {interPreterRemoteTrack(p)}
                            <td className="dt-head-center dt-body-center sorting_1">
                              <a>{p.booth_language_name}</a>
                            </td>
                            <td className=" dt-head-center dt-body-center "></td>
                            <td className=" dt-head-center dt-body-center ">
                              {getMuteStatus(p.participant_id) ? (
                                <i
                                  className="fa-solid fa-microphone-slash"
                                  style={{ font_size: "1rem" }}
                                  datatoggle="tooltip"
                                  title="Mic Status"
                                  dataoriginaltitle="Mic Status"
                                ></i>
                              ) : (
                                <i
                                  className="fa-solid fa-microphone"
                                  style={{ font_size: "1rem" }}
                                  datatoggle="tooltip"
                                  title="Mic Status"
                                  dataoriginaltitle="Mic Status"
                                ></i>
                              )}
                            </td>
                            <td className=" dt-head-center dt-body-center ">
                              {getAudioStatus(p.participant_id)}
                            </td>
                          </tr>
                        );
                      })}
                    {/* {oParticipantData.length > 0 &&
                      oParticipantData.map((p, index) => {
                        return (
                          <tr className="odd" key={index}>
                            {interPreterRemoteTrack(p)}
                            <td className="dt-head-center dt-body-center sorting_1">
                              <a>{p.booth_language_name}</a>
                            </td>
                            <td className=" dt-head-center dt-body-center "></td>
                            <td className=" dt-head-center dt-body-center ">
                              {getMuteStatus(p.participant_id) ? (
                                <i
                                  className="fa-solid fa-microphone-slash"
                                  style={{ font_size: "1rem" }}
                                  datatoggle="tooltip"
                                  title="Mic Status"
                                  dataoriginaltitle="Mic Status"
                                ></i>
                              ) : (
                                <i
                                  className="fa-solid fa-microphone"
                                  style={{ font_size: "1rem" }}
                                  datatoggle="tooltip"
                                  title="Mic Status"
                                  dataoriginaltitle="Mic Status"
                                ></i>
                              )}
                            </td>
                            <td className=" dt-head-center dt-body-center ">
                              {getAudioStatus(p.participant_id)}
                            </td>
                          </tr>
                        );
                      })} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="participnatModal"
        tabIndex="-1"
        aria-labelledby="participnatModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-sm">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ position: "relative", display: "flex" }}
            >
              <h4 className="modal-title">Interpreter</h4>
              <button
                type="button"
                id={"closeModal"}
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div id="participant-form">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control bg-light border border-dark"
                    onChange={(e) => {
                      setPFormValues({
                        ...pFormValues,
                        name: e.target.value,
                      });
                    }}
                    value={pFormValues.name}
                  />
                </div>
                <div className="form-check form-check-inline ml-2">
                  <input
                    type="checkbox"
                    name="is_connected"
                    value={pFormValues.is_connected}
                    checked={pFormValues.is_connected == "1" ? "checked" : ""}
                    className="form-check-input"
                    onChange={() => {
                      setPFormValues({
                        ...pFormValues,
                        is_connected:
                          pFormValues.is_connected == "1" ? "0" : "1",
                      });
                    }}
                  />
                  <div className="form-check-label">Connected</div>
                </div>
              </div>
            </div>
            <div className="modal-footer" id="modal-footer">
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={updateInterpreterData.isPending}
                  onClick={() => updateParticipant()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// function compare(preProps, nextProps) {
//   console.log("nextProps.eventDetail=>", nextProps);

//   console.log("length=>", Object.keys(nextProps.eventDetail));

//   let check1 =
//     preProps.participantData?.length === nextProps?.participantData?.length;
//   let check2 =
//     preProps.oParticipantData?.length === nextProps.oParticipantData?.length;
//   let check3 =
//     Object.keys(preProps.eventDetail).length ===
//     Object.keys(nextProps.eventDetail).length;
//   // JSON.stringify(preProps.eventDetail) ===
//   // JSON.stringify(nextProps.eventDetail);

//   console.log("check1 =>", check1, "check2=>", check2, "check3=>", check3);

//   return !(check1 || check2 || check3);
// }

export default memo(InterpreterListing);
