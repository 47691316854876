import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getQuickChat,
  sendQuickMessageService,
  quickChatReleaseService,
} from "../Services/interceptorServices";
export default function QuickMessages(props) {
  const { participantId, booth_language_id, participant_type } = useSelector(
    (state) => state.connection
  );
  const [radioSelect, setRadioSelect] = useState("");
  const [chatMessage, setChatMessage] = useState({
    "btn-groups": [],
    btns: [],
  });
  useEffect(() => {
    getChatMessage();
  }, []);

  const getChatMessage = () => {
    getQuickChat(participantId)
      .then((resp) => {
        if (resp && resp.data && resp.data.code === 200) {
          setChatMessage(resp.data.data);
        }
      })
      .catch((err) => {
        console.log("***ERR**", err);
      });
  };

  const sendQuickMessage = (quickchat_id, to) => {
    try {
      if (to === "Booth" && !radioSelect && participant_type === "moderator") {
        alert(
          "Please select a booth to which to send your message, then try again."
        );
        return false;
      }
      document.getElementById("chat-spinner").style.display = "block";
      sendQuickMessageService({
        participant_id: participantId,
        booth_language_id: radioSelect || booth_language_id,
        quickchat_id,
      })
        .then((resp) => {
          if (resp && resp.data && resp.data.code === 200) {
          }
        })
        .catch((err) => {
          console.log("***ERR**", err);
        })
        .finally(() => {
          document.getElementById("activity-log").scrollTo(0, 0);
          document.getElementById("chat-spinner").style.display = "none";
        });
    } catch (er) {
      console.log("Err=>", er);
    }
  };
  const sendQuickRelease = () => {
    try {
      document.getElementById("chat-spinner").style.display = "block";
      quickChatReleaseService(participantId)
        .then((resp) => {
          if (resp && resp.data && resp.data.code === 200) {
          }
        })
        .catch((err) => {
          console.log("***ERR**", err);
        })
        .finally(() => {
          document.getElementById("activity-log").scrollTo(0, 0);
          document.getElementById("chat-spinner").style.display = "none";
        });
    } catch (err) {
      console.log("Err=>", err);
    }
  };
  const onMouseOver = (e) => {
    e.target.style.fontWeight = "bold";
  };
  const onMouseOut = (e) => {
    e.target.style.fontWeight = "normal";
  };

  return (
    <div className={`${participant_type !== "moderator" ? "card" : " "} `}>
      <div className="card-body text-center">
        <div className="hint" id="quickchats-hint">
          Use these buttons to quickly send messages to other groups of
          participants attending the event.
        </div>
        <div
          className={`mb-2 text-light ${
            participant_type === "moderator" ? "btn-group" : " "
          } `}
        >
          Quick Messages
          {participant_type === "moderator" && (
            <i
              className="fa-solid fa-arrow-right ml-2 mr-2"
              style={{ alignSelf: "center" }}
            ></i>
          )}
        </div>

        {chatMessage["btn-groups"].length > 0 &&
          chatMessage["btn-groups"].map((data, index) => (
            <div className="btn-group" key={`g_${index}`}>
              <button
                type="button"
                className="btn btn-sm btn-success dropdown-toggle mr-1 mb-1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                key={index}
              >
                <i className="fa-solid fa-comment mr-2" key={`i_${index}`}></i>
                to {data.to}
              </button>
              <div
                className="dropdown-menu"
                x-placement="bottom-start"
                style={{
                  position: "absolute",
                  willChange: "transform",
                  top: 0,
                  left: 0,
                  transform: "translate3d(0px, 31px, 0px)ß",
                }}
              >
                {data.booth_selections.length > 0 && (
                  <h6
                    className="dropdown-header bg-success text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    Which booth?
                  </h6>
                )}
                {data.booth_selections.length > 0 &&
                  data.booth_selections.map((language, index1) => {
                    return (
                      <div
                        className="booth-lang px-3 py-1 bg-light"
                        key={`l_${index1}`}
                      >
                        <div className="form-check mb-1" key={`div_${index1}`}>
                          <input
                            type="radio"
                            name="booth"
                            className="form-radio"
                            id={`radio_${language[0]}`}
                            key={`radio_${index1}`}
                            onClick={(e) => {
                              setRadioSelect(language[0]);
                              e.stopPropagation();
                            }}
                            defaultValue={language[0]}
                          />
                          <label
                            key={`label_${index1}`}
                            className="radio-label"
                            htmlFor={`radio_${language[0]}`}
                            onMouseOver={onMouseOver}
                            onMouseOut={onMouseOut}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {language[1]}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                <div className="dropdown-divider"></div>
                {data.quickchats &&
                  data.quickchats.map((chat, index1) => (
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        sendQuickMessage(chat.quickchat_id, data.to);
                        if (chat.is_alert === "1") {
                          props.playChimeCopy();
                        }
                      }}
                      key={index1}
                    >
                      {chat.label}
                      {chat.is_alert === "1" && (
                        <i
                          className="fa-solid fa-triangle-exclamation text-warning ml-2"
                          key={index1}
                        ></i>
                      )}
                    </a>
                  ))}
                {data.include_interpreters_released === true && (
                  <Fragment>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item text-danger"
                      href="# "
                      onClick={() => {
                        sendQuickRelease();
                      }}
                    >
                      Interpreters are released
                    </a>
                  </Fragment>
                )}
              </div>
            </div>
          ))}
        {chatMessage["btns"].length > 0 &&
          chatMessage["btns"].map((chatbtn, index) => {
            return (
              <div className="btn-group" key={index}>
                <button
                  type="button"
                  className={`${
                    chatbtn.is_alert === "0" &&
                    "btn btn-sm btn-info text-white mr-1 mb-1"
                  } ${
                    chatbtn.is_alert === "1" &&
                    "btn btn-sm btn-warning text-white mr-1 mb-1"
                  } `}
                  onClick={() => {
                    sendQuickMessage(chatbtn.quickchat_id);
                    if (chatbtn.is_alert === "1") {
                      props.playChimeCopy();
                    }
                  }}
                >
                  <i className="fa-solid fa-comment mr-2"></i>
                  {chatbtn.label}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
}
