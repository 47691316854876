import { useDispatch, useSelector } from "react-redux";
import { commonMessage } from "../../helpers/commonMessage";
import { setVideoEnabledAction } from "../../Redux/Reducers/Connection";
import { toast } from "react-toastify";

export function MuteControls({ eventDetail }) {
  const {
    localTracks,
    videoEnabled,
    audioEnabled,
    mainMicMute,
    showSettings,
    showQuickMessages,
  } = useSelector((state) => state.connection);

  const dispatch = useDispatch();

  const handleVideoToggle = () => {
    const videoTrack = localTracks.find((track) => track.getType() === "video");

    if (!videoTrack) {
      toast.dismiss();
      return toast.error(commonMessage.videoDeviceNotAvailable);
    }

    dispatch(setVideoEnabledAction(!videoEnabled));

    try {
      videoTrack[videoTrack.isMuted() ? "unmute" : "mute"]();
    } catch (err) {
      toast.error(commonMessage.videoDeviceNotAvailable);
    }
  };

  // const videoIconClass = `fa-solid ${
  //   videoEnabled ? "fa-toggle-on" : "fa-toggle-off"
  // } fa-xl text-primary mr-2 ${
  //   eventDetail?.enable_video !== "1" ? "text-muted" : ""
  // }`;

  const videoIconClass = `fa-solid ${
    videoEnabled ? "fa-toggle-on" : "fa-toggle-off"
  } fa-xl text-primary mr-2`;

  return (
    <>
      <div className="mb-1">
        <a onClick={handleVideoToggle}>
          <i id="enable-video-switch" className={videoIconClass}></i>
        </a>
        Enable My Video
      </div>
    </>
  );
}
