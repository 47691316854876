/* global JitsiMeetJS */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disconnected } from "./Redux/Reducers/Connection";
import LandingPage from "./pages/LandingPage";
import ConferenceWrapper from "./pages/ConferenceWrapper";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NoInternetConnectionWrapper from "./components/NoInternetConnectionWrapper";
import { ToastContainer } from "react-toastify";
import LimiterNode from "audio-limiter";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import sign from "jwt-encode";

const AudioContext = window.AudioContext || window.webkitAudioContext;

export default function App() {
  const dispatch = useDispatch();
  const {
    themeMode,
    isEventJoined,
    videoInputDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
    displayName,
    event_id,
    booth_language_id,
  } = useSelector((state) => state.connection);

  const [connection, setConnection] = useState(null);

  // Initialize audio context and limiter
  window.APP.audioContext = new AudioContext();
  window.APP.audioContextDestination =
    window.APP.audioContext.createMediaStreamDestination();
  window.APP.limiter = new LimiterNode(window.APP.audioContext, {
    time: 0.005,
  });

  // Clean up function to handle disconnecting
  const unloadEventListener = () => {
    dispatch(disconnected());
  };

  // Jitsi connection options
  const initializeJitsi = () => {
    const options = {
      hosts: {
        domain: "meet.jitsi",
        muc: "muc.meet.jitsi",
      },
      serviceUrl: `https://${process.env.REACT_APP_JITSI_URL}/http-bind`,
      deploymentInfo: {},
    };

    const initOptions = {
      disableAP: true,
      disableAEC: true,
      disableNS: true,
      disableAGC: true,
      disableHPF: true,
      stereo: true,
      disableLipSync: true,
      maxaveragebitrate: 510000,
      enableTcc: true,
    };

    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    JitsiMeetJS.init(initOptions);

    // JWT Token generation
    const jwtPayload = {
      context: {
        user: {
          name: displayName,
        },
      },
      aud: process.env.REACT_APP_JITSI_APP_ID,
      iss: process.env.REACT_APP_JITSI_SECRET_ID,
      sub: process.env.REACT_APP_JITSI_URL,
      room: `${process.env.REACT_APP_JITSI_ROOM}-${event_id}-${booth_language_id}`,
      exp: 2550716253,
      nbf: 1697004697,
    };

    const token = sign(jwtPayload, process.env.REACT_APP_JWT_SECRET);
    const connection = new JitsiMeetJS.JitsiConnection(null, token, options);

    setConnection(connection);
    connection.connect();

    window.addEventListener("beforeunload", unloadEventListener);
  };

  useEffect(() => {
    initializeJitsi();

    return () => {
      dispatch(disconnected());
      window.removeEventListener("beforeunload", unloadEventListener);
    };
  }, [dispatch]); // Added dispatch to dependency array

  return (
    <div className={`App ${themeMode}`}>
      <ToastContainer className="toast-position" position="top-center" />
      <NoInternetConnectionWrapper>
        <Header />
        {!isEventJoined ? (
          <LandingPage />
        ) : (
          <ConferenceWrapper
            connection={connection}
            videoInputDeviceId={videoInputDeviceId}
            audioInputDeviceId={audioInputDeviceId}
            audioOutputDeviceId={audioOutputDeviceId}
          />
        )}
        <Footer />
      </NoInternetConnectionWrapper>
    </div>
  );
}

// /* global JitsiMeetJS */
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { disconnected } from "./Redux/Reducers/Connection";
// import LandingPage from "./pages/LandingPage";
// import ConferenceWrapper from "./pages/ConferenceWrapper";
// import Header from "./components/Header";
// import Footer from "./components/Footer";
// import { ToastContainer } from "react-toastify";
// import LimiterNode from "audio-limiter";
// import NoInternetConnectionWrapper from "./components/NoInternetConnectionWrapper";
// import "react-toastify/dist/ReactToastify.css";
// import "./App.css";
// import sign from "jwt-encode";

// const AudioContext = window.AudioContext || window.webkitAudioContext;

// export default function App() {
//   const dispatch = useDispatch();
//   const {
//     themeMode,
//     isEventJoined,
//     videoInputDeviceId,
//     audioInputDeviceId,
//     audioOutputDeviceId,
//     displayName,
//     event_id,
//     booth_language_id,
//   } = useSelector((state) => state.connection);
//   const [connection, setConnection] = useState(null);
//   window.APP.audioContext = new AudioContext();
//   window.APP.audioContextDestination =
//     window.APP.audioContext.createMediaStreamDestination();
//   window.APP.limiter = new LimiterNode(window.APP.audioContext, {
//     time: 0.005,
//   });

//   const unloadEventListener = () => {
//     dispatch(disconnected());
//   };

//   const getStarted = () => {

//     const options = {
//       hosts: {
//         domain: "meet.jitsi",
//         muc: "muc.meet.jitsi",
//       },
//       serviceUrl: `https://${process.env.REACT_APP_JITSI_URL}/http-bind`,
//       deploymentInfo: {},
//     };
//     const initOptions = {
//       disableAP: true,
//       disableAEC: true,
//       disableNS: true,
//       disableAGC: true,
//       disableHPF: true,
//       stereo: true,
//       disableLipSync: true,
//       maxaveragebitrate: 510000,
//       enableTcc: true,
//     };

//     JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
//     JitsiMeetJS.init(initOptions);

//     let plainObj = {
//       context: {
//         user: {
//           name: displayName,
//         },
//       },
//       aud: process.env.REACT_APP_JITSI_APP_ID,
//       iss: process.env.REACT_APP_JITSI_SECRET_ID,
//       sub: process.env.REACT_APP_JITSI_URL,
//       room: `${process.env.REACT_APP_JITSI_ROOM}-${event_id}-${booth_language_id}`,
//       exp: 2550716253,
//       nbf: 1697004697,
//     };

//     let jw = sign(plainObj, process.env.REACT_APP_JWT_SECRET);

//     let con = new JitsiMeetJS.JitsiConnection(null, jw, options);
//     setConnection(con);
//     con.connect();

//     window.addEventListener("beforeunload", unloadEventListener);
//   };

//   useEffect(() => {
//     getStarted();

//     return () => {
//       dispatch(disconnected());
//       window.removeEventListener("beforeunload", unloadEventListener);
//     };
//   }, []);

//   return (
//     <div className={`App ${themeMode}`}>
//       <ToastContainer className="toast-position" position="top-center" />
//       <NoInternetConnectionWrapper>
//         <Header />
//         {!isEventJoined && <LandingPage />}
//         {isEventJoined && (
//           <ConferenceWrapper
//             connection={connection}
//             videoInputDeviceId={videoInputDeviceId}
//             audioInputDeviceId={audioInputDeviceId}
//             audioOutputDeviceId={audioOutputDeviceId}
//           />
//         )}
//         <Footer />
//       </NoInternetConnectionWrapper>
//     </div>
//   );
// }
