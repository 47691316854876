/* global JitsiMeetJS */

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { commonMessage } from "../../helpers/commonMessage";
import Cookies from "js-cookie";

const initialState = {
  boothName: "",
  connection: null,
  room: null,
  displayName: "",
  booth_language_id: "",
  devices: [],
  remoteTracks: {},
  remoteTracksArray: [],
  localTracks: [],
  isJoined: false,
  currentUserId: 0,
  joinedUsers: {},
  selecteduser: "",
  videoEnabled: true,
  audioEnabled: true,
  mainMicMute: true,
  boothmatesMute: false,
  enableTextChat: true,
  chatView: "bottom-top",
  boothmateVolume: 50,
  boothmateAudio: 0,
  allAudioVolumeForMod: 0.5,
  videoInputDeviceId: "",
  audioInputDeviceId: "",
  audioOutputDeviceId: "",
  selectedTab: 0,
  isEventJoined: false,
  event_id: 0,
  interpreterSortListType: "",
  participant_type: "interpreter",
  participantId: 0,
  themeMode: "dark",
  consoleMode: "ADVANCED",
  chimeEnabled: true,
  chimeVolume: 0.5,
  brickLimiter: true,
  showSettings: true,
  showQuickMessages: true,
  thresholdValue: "",
  attackValue: "",
  releaseValue: "",
  pregainValue: "",
  postgainValue: "",
  defaultBrickLimiter: "",
  localStats: {},
  allRemoteStats: {},
  monitoringMode: false,
  selectedVirtualCableId: "",
  zoomWebxAudioMute: false,
  zoomWebxVolume: 50,
};

export const connectionSlice = createSlice({
  name: "connection",
  initialState: initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.boothName = "";
      state.displayName = "";
      state.booth_language_id = "";
      state.isJoined = false;
      state.currentUserId = 0;
      state.joinedUsers = {};
      state.selecteduser = "";
      state.videoEnabled = true;
      state.audioEnabled = true;
      state.mainMicMute = true;
      state.boothmatesMute = false;
      state.boothmateVolume = 50;
      state.boothmateAudio = 0;
      state.videoInputDeviceId = "";
      state.audioInputDeviceId = "";
      state.audioOutputDeviceId = "";
      state.selectedTab = 0;
      state.isEventJoined = false;
      state.event_id = 0;
      state.participant_type = "interpreter";
      state.participantId = 0;
      state.brickLimiter = true;
      state.showSettings = true;
      state.showQuickMessages = true;
      state.interpreterSortListType = "";
      state.monitoringMode = false;
      state.selectedVirtualCableId = "";
      state.zoomWebxAudioMute = false;
      state.zoomWebxVolume = 50;
    },

    changeLocalStats: (state, action) => {
      state.localStats = action.payload;
    },
    changeAllRemoteStats: (state, action) => {
      state.allRemoteStats = { ...state.allRemoteStats, ...action.payload };
    },
    ChangeConsoleMode: (state, action) => {
      state.consoleMode = action.payload;
    },
    changeThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    changeChimeVolume: (state, action) => {
      state.chimeVolume = Number(action.payload);
    },
    changeChimeEnabled: (state, action) => {
      state.chimeEnabled = action.payload;
    },
    changeBrickLimiter: (state, action) => {
      state.brickLimiter = action.payload;
    },
    changeShowSettings: (state, action) => {
      state.showSettings = action.payload;
    },
    changeShowQuickMessages: (state, action) => {
      state.showQuickMessages = action.payload;
    },
    changeDefaultBrickLimiter: (state, action) => {
      state.defaultBrickLimiter = action.payload;
    },
    changeThresholdValue: (state, action) => {
      state.thresholdValue = action.payload;
    },
    changeAttackValue: (state, action) => {
      state.attackValue = action.payload;
    },
    changeReleaseValue: (state, action) => {
      state.releaseValue = action.payload;
    },
    changePregainValue: (state, action) => {
      state.pregainValue = action.payload;
    },
    changePostgainValue: (state, action) => {
      state.postgainValue = action.payload;
    },
    setConnection: (state, action) => {
      const { connection } = action.payload;
      state.connection = connection;
    },
    connectionEstablished: (state, action) => {
      window.APP.room = action.payload;
    },
    changeInterpreterSortType: (state, action) => {
      state.interpreterSortListType = action.payload;
    },
    devicesListUpdated: (state, action) => {
      state.devices = action.payload;

      let devices = action.payload;

      const audioOutputDevices = devices.filter(
        (d) => d.kind === "audiooutput"
      );
      const audioInputDevices = devices.filter((d) => d.kind === "audioinput");
      const videoInputDevices = devices.filter((d) => d.kind === "videoinput");

      if (!state.audioOutputDeviceId && audioOutputDevices.length > 0) {
        state.audioOutputDeviceId = audioOutputDevices[0].deviceId;
      }

      // if (audioOutputDevices.length === 0 || (state.audioOutputDeviceId && audioOutputDevices.find(dv => dv.deviceId !== state.audioOutputDeviceId))) {
      //     state.audioOutputDeviceId = "";
      // }

      if (!state.audioInputDeviceId && audioInputDevices.length > 0) {
        state.audioInputDeviceId = audioInputDevices[0].deviceId;
      }
      // if (audioInputDevices.length === 0 || (state.audioInputDeviceId && audioInputDevices.find(dv => dv.deviceId !== state.audioInputDeviceId))) {
      //     state.audioInputDeviceId = "";
      // }

      if (!state.videoInputDeviceId && videoInputDevices.length > 0) {
        state.videoInputDeviceId = videoInputDevices[0].deviceId;
      }
      // if (videoInputDevices.length === 0) {
      //     state.videoInputDeviceId = "";
      // }
    },
    setDisplayName: (state, action) => {
      const room = window.APP.room;
      if (room) {
        room.setDisplayName(action.payload);
        room.setLocalParticipantProperty(
          "participant_type",
          state.participant_type
        );
        room.setLocalParticipantProperty("participantId", state.participantId);
        room.setLocalParticipantProperty(
          "boothmatesMute",
          state.boothmatesMute
        );
      }
      state.displayName = action.payload;
    },
    setBoothId: (state, action) => {
      state.booth_language_id = action.payload;
    },
    setParticipantId: (state, action) => {
      state.participantId = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setSelecteduser: (state, action) => {
      state.selecteduser = action.payload;
    },
    setVideoEnabled: (state, action) => {
      state.videoEnabled = action.payload;
    },
    setAudioEnabled: (state, action) => {
      state.audioEnabled = action.payload;
    },
    setEnableTextChat: (state, action) => {
      state.enableTextChat = action.payload;
    },
    setMainMicMute: (state, action) => {
      state.mainMicMute = action.payload;
    },
    setBoothmatesMute: (state, action) => {
      state.boothmatesMute = action.payload;
    },
    setBoothmateVolume: (state, action) => {
      state.boothmateVolume = action.payload;
    },
    setAllAudioVolume: (state, action) => {
      state.allAudioVolumeForMod = Number(action.payload);
    },
    setBoothmateAudio: (state, action) => {
      state.boothmateAudio = action.payload;
    },
    setAudioVideoDeviceId: (state, action) => {
      const { deviceId, type } = action.payload;
      if (type === "audioinput") {
        state.audioInputDeviceId = deviceId;
        Cookies.set("audioInput", deviceId, { expires: 7 });
      }
      if (type === "videoinput") {
        state.videoInputDeviceId = deviceId;
        Cookies.set("videoInput", deviceId, { expires: 7 });
      }
      if (type === "audiooutput") {
        state.audioOutputDeviceId = deviceId;
        Cookies.set("audioOutput", deviceId, { expires: 7 });
      }
    },
    conferenceJoined: (state, action) => {
      state.isJoined = true;
      console.log(" ***** room joined *** ", state.localTracks);
      // for (let i = 0; i < state.localTracks.length; i++) {
      //     console.log(" ***** track added *** ", state.localTracks[i]);
      //     window.APP.room.addTrack(state.localTracks[i]);
      // }
    },
    disconnected: (state, action) => {
      state.localTracks.map((item) => {
        item.dispose();
      });
      window.APP?.room?.leave();
      state.localTracks = [];
      state?.connection?.disconnect();
    },
    userJoined: (state, action) => {
      const joinedUsers = state.joinedUsers;
      joinedUsers[action.payload.id] = action.payload.user;
      state.joinedUsers = joinedUsers;

      // Send Request for other joined users for timer (started or Reset)
      let currentTimezone = localStorage.getItem("currentTimezone");
      let savedCountDownDate = localStorage.getItem("currentTime");
      let selectedMinutes = localStorage.getItem("selectedMinutes");
      const room = window.APP.room;
      if (room && savedCountDownDate && selectedMinutes) {
        // if (action.payload.user._properties.timerValue && action.payload.user._properties.timerValue != -1) {
        setTimeout(() => {
          room.setLocalParticipantProperty("timerValue", 0);
          room.setLocalParticipantProperty("timerStartTime", 0);
          room.setLocalParticipantProperty("currentTimezone", currentTimezone);
          room.setLocalParticipantProperty(
            "timerStartTime",
            savedCountDownDate
          );
          room.setLocalParticipantProperty("timerValue", selectedMinutes);
        }, 1000);
        // }
      }
    },
    userLeft: (state, { payload }) => {
      const joinedUsers = state.joinedUsers;

      delete joinedUsers[payload.id];

      let id = payload.id;
      const remoteTracks = state.remoteTracks;

      if (!remoteTracks[id]) {
        return;
      }

      if (remoteTracks[id]) {
        delete remoteTracks[id];
      }
      state.remoteTracks = remoteTracks;
      state.joinedUsers = joinedUsers;
    },
    remoteTrackAdded: (state, action) => {
      const track = action.payload;

      if (track.isLocal()) {
        return;
      }

      let remoteTracks = state.remoteTracks;
      let remoteTracksArray = state.remoteTracksArray;

      const participant = track.getParticipantId();

      let existingTrack = remoteTracksArray.find(
        (item) => item.id === participant
      );

      if (existingTrack) {
        remoteTracksArray = remoteTracksArray.map((item) => {
          if (item.id == participant) {
            return {
              id: participant,
              tracks: [...item.tracks, track],
            };
          }
          return item;
        });
      } else {
        remoteTracksArray.push({
          id: participant,
          tracks: [track],
        });
      }

      if (!remoteTracks[participant]) {
        remoteTracks[participant] = [];
      }
      remoteTracks[participant].push(track);
      state.remoteTracks = remoteTracks;
      state.remoteTracksArray = remoteTracksArray;
    },
    remoteTrackRemoved: (state, action) => {
      const track = action.payload;

      console.log(" **** remote track removed ", track.getId());

      if (track.isLocal()) {
        return;
      }

      let remoteTracks = state.remoteTracks;

      let remoteTracksArray = state.remoteTracksArray;

      const participant = track.getParticipantId();

      if (remoteTracks[participant]) {
        const remoteUserName = state.joinedUsers[participant]?._displayName;

        // if (state.participant_type === "interpreter") {
        //   toast.dismiss();
        //   toast.info(`${remoteUserName} interpreter left the console`);
        // }

        remoteTracks[participant] = remoteTracks[participant].filter(
          (exTrack) => {
            return exTrack.getId() !== track.getId();
          }
        );
        remoteTracksArray = remoteTracksArray.filter((item) => {
          return item.id !== participant;
        });
        if (remoteTracks[participant].length == 0) {
          delete remoteTracks[participant];
        }
      }

      state.remoteTracks = { ...remoteTracks };
      state.remoteTracksArray = remoteTracksArray;
    },
    remoteTrackMuteChaned: (state, action) => {
      const track = action.payload;

      if (track.isLocal()) {
        return;
      }

      const participant = track.getParticipantId();

      let remoteTracks = state.remoteTracks;

      // if (remoteTracks[participant]) {
      //     remoteTracks[participant] = remoteTracks[participant].map((item, index) => {
      //         if (item.getId() === track.getId()) {
      //             if (item.getType() === 'audio') {
      //                 item.detach(document.getElementById(`${participant}-audio-${index}`));
      //             } else {
      //                 item.detach(document.getElementById(`${participant}-video-${index}`));
      //             }
      //         }
      //         return item;
      //     });
      // }
      state.remoteTracks = remoteTracks;

      let remoteTracksArray = state.remoteTracksArray;

      remoteTracksArray = remoteTracksArray.map((item) => {
        if (item.id === participant) {
          return {
            id: participant,
            tracks: item.tracks,
          };
        }
        return item;
      });

      state.remoteTracksArray = remoteTracksArray;
    },
    localTracksAdded: (state, action) => {
      state.localTracks = action.payload;
      let localTracks = action.payload;
      for (let i = 0; i < localTracks.length; i++) {
        if (localTracks[i].getType() === "audio") {
          localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
            () => console.log("local track muted")
          );
          localTracks[i].addEventListener(
            JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
            () => console.log("local track stoped")
          );
          localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
            (deviceId) =>
              console.log(
                `track audio output device was changed to ${deviceId}`
              )
          );
        }

        if (
          (!state.audioEnabled || (state.audioEnabled && state.mainMicMute)) &&
          localTracks[i].getType() === "audio"
        ) {
          localTracks[i].mute();
        }

        if (!state.videoEnabled && localTracks[i].getType() === "video") {
          localTracks[i].mute();
        }

        console.log(
          " ***** state.isJoined *** ",
          state.isJoined,
          state.localTracks
        );
        // if (state.isJoined) {
        window.APP?.room?.addTrack(localTracks[i]);
        // }
      }
    },
    localTracksReplaced: (state, action) => {
      let oldLocalTracks = state.localTracks;
      state.localTracks = action.payload;
      let localTracks = action.payload;
      for (let i = 0; i < localTracks.length; i++) {
        if (localTracks[i].getType() === "audio") {
          localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
            () => console.log("local track muted")
          );
          localTracks[i].addEventListener(
            JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
            () => console.log("local track stoped")
          );
          localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
            (deviceId) =>
              console.log(
                `track audio output device was changed to ${deviceId}`
              )
          );
        }

        if (
          (!state.audioEnabled || (state.audioEnabled && state.mainMicMute)) &&
          localTracks[i].getType() === "audio"
        ) {
          localTracks[i].mute();
        }

        if (!state.videoEnabled && localTracks[i].getType() === "video") {
          localTracks[i].mute();
        }

        let oldTrack = oldLocalTracks.find(
          (tr) => tr.getType() === localTracks[i].getType()
        );
        console.log(" ***** replaceTrack *** ", oldTrack, localTracks[i]);
        window.APP.room.replaceTrack(oldTrack, localTracks[i]);
      }
    },
    localTrackRemoved: (state, { payload }) => {
      let localTracks = state.localTracks;

      if (localTracks && localTracks.length) {
        localTracks.forEach((track) => {
          track.dispose();
          window.APP.room.removeTrack(track);
        });
      }
      state.localTracks = [];
    },
    localSingleTrackRemoved: (state, { payload }) => {
      let localTracks = state.localTracks;

      if (localTracks && localTracks.length) {
        let localTrack = localTracks.find((exTrack) => {
          return (
            exTrack.getType() === (payload === "audio" ? "audio" : "video")
          );
        });
        localTrack.dispose();
        window.APP.room?.removeTrack(localTrack);
        localTracks = localTracks.filter((exTrack) => {
          return (
            exTrack.getType() === (payload === "audio" ? "video" : "audio")
          );
        });
      }
      state.localTracks = localTracks;
    },
    localSingleTrackAdded: (state, action) => {
      let { track: newAddedTrack, type } = action.payload;

      let localTracks = state.localTracks;
      let oldTrack = localTracks.find((tr) => tr.getType() === type);
      localTracks = localTracks.filter((tr) => tr.getType() !== type);
      localTracks.unshift(newAddedTrack);
      state.localTracks = localTracks;

      if (type === "audio") {
        newAddedTrack.addEventListener(
          JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
          () => console.log("local track muted")
        );
        newAddedTrack.addEventListener(
          JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
          () => console.log("local track stoped")
        );
        newAddedTrack.addEventListener(
          JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
          (deviceId) =>
            console.log(`track audio output device was changed to ${deviceId}`)
        );
      }

      if (
        (!state.audioEnabled || (state.audioEnabled && state.mainMicMute)) &&
        newAddedTrack.getType() === "audio"
      ) {
        newAddedTrack.mute();
      }

      if (!state.videoEnabled && type === "video") {
        newAddedTrack.mute();
      }

      window.APP.room?.replaceTrack(oldTrack, newAddedTrack);
    },
    setEventJoined: (state, action) => {
      state.isEventJoined = action.payload === true ? true : false;
    },
    setEventId: (state, action) => {
      state.event_id = action.payload || 0;
    },
    setParticipantType: (state, action) => {
      state.participant_type = action.payload || "interpreter";
    },
    setChatView: (state, action) => {
      state.chatView = action.payload || "top-bottom";
    },
    setRemoteUserName: (state) => {
      const joinedUsers = state.joinedUsers;
      state.joinedUsers = { ...joinedUsers };
    },
    setMonitoringMode: (state) => {
      state.monitoringMode = !state.monitoringMode;
    },
    setVirtualCableId: (state, { payload }) => {
      state.selectedVirtualCableId = payload;
    },
    setZoomWebxAudioMute: (state) => {
      state.zoomWebxAudioMute = !state.zoomWebxAudioMute;
    },
    setZoomWebxVolume: (state, action) => {
      state.zoomWebxVolume = action.payload;
    },
    removeInputDeviceId: (state, action) => {
      if (action.payload === "audioInput") {
        state.audioInputDeviceId = "";
      } else if (action.payload === "videoInput") {
        state.videoInputDeviceId = "";
      }
    },
    setBoothName: (state, { payload }) => {
      state.boothName = payload;
    },
  },
});

export const {
  connectionEstablished,
  setConnection,
  devicesListUpdated,
  setSelecteduser,
  setDisplayName,
  setBoothId,
  setParticipantId,
  remoteTrackAdded,
  remoteTrackMuteChaned,
  remoteTrackRemoved,
  conferenceJoined,
  userJoined,
  userLeft,
  localTracksAdded,
  localTracksReplaced,
  disconnected,
  localTrackRemoved,
  localSingleTrackRemoved,
  localSingleTrackAdded,
  setVideoEnabled,
  setAudioEnabled,
  setEnableTextChat,
  setMainMicMute,
  setBoothmatesMute,
  setBoothmateVolume,
  setAllAudioVolume,
  setBoothmateAudio,
  setAudioVideoDeviceId,
  setSelectedTab,
  setEventJoined,
  setEventId,
  setParticipantType,
  setInitialState,
  changeThemeMode,
  ChangeConsoleMode,
  changeBrickLimiter,
  changeThresholdValue,
  changeReleaseValue,
  changeAttackValue,
  changePregainValue,
  changePostgainValue,
  changeDefaultBrickLimiter,
  changeChimeEnabled,
  changeShowSettings,
  changeShowQuickMessages,
  changeChimeVolume,
  setChatView,
  setRemoteUserName,
  changeLocalStats,
  changeAllRemoteStats,
  changeInterpreterSortType,
  setMonitoringMode,
  setVirtualCableId,
  setZoomWebxAudioMute,
  setZoomWebxVolume,
  removeInputDeviceId,
  setBoothName,
} = connectionSlice.actions;

export const setConnectionAction =
  ({
    meetingId,
    connection,
    displayName,
    videoInputDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
    participant_type,
  }) =>
  (dispatch) => {
    console.log("connection>", connection);
    dispatch(setConnection(connection));
    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      () => {
        dispatch(
          connectionEstablishedAction({
            meetingId,
            connection,
            displayName,
            videoInputDeviceId,
            audioInputDeviceId,
            audioOutputDeviceId,
            participant_type,
          })
        );
      }
    );

    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      onConnectionFailed
    );

    JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      (devices) => {
        dispatch(setDevicesAction(devices));
      }
    );
  };

export const remoteTrackAddedAction = (track) => (dispatch) => {
  if (track.isLocal()) {
    return;
  }

  dispatch(remoteTrackAdded(track));

  track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () =>
    console.log("remote track muted")
  );
  track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () =>
    console.log("remote track stoped")
  );
  track.addEventListener(
    JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
    (deviceId) =>
      console.log(`track audio output device was changed to ${deviceId}`)
  );
};

export const connectionEstablishedAction =
  ({
    meetingId,
    connection,
    displayName,
    videoInputDeviceId,
    audioInputDeviceId,
    participant_type,
  }) =>
  async (dispatch) => {
    let confRoom = meetingId || "meet-room-1";
    console.log("***meetingId****", confRoom);

    const configuration = {
      ...confOptions,
    };

    if (participant_type === "moderator") {
      configuration.startAudioOnly = true;
    }

    const room = connection.initJitsiConference(confRoom, configuration);
    room.setReceiverVideoConstraint(360);
    room.setSenderVideoConstraint(360);

    dispatch(connectionEstablished(room));

    dispatch(setDisplayName(displayName));

    if (participant_type === "interpreter") {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      } catch (err) {
        console.log("err->", err);
      }
      JitsiMeetJS.createLocalTracks({
        devices: ["audio", "video"],
        cameraDeviceId: videoInputDeviceId || "default",
        micDeviceId: audioInputDeviceId || "default",
      })
        .then((tracks) => {
          dispatch(localTracksAddedAction(tracks));
          room.join();
        })
        .catch(async () => {
          try {
            await navigator.mediaDevices.getUserMedia({ video: true });
          } catch (err) {
            console.log("err-.", err);
          }

          JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            cameraDeviceId: videoInputDeviceId,
          })
            .then((tracks) => {
              dispatch(localTracksAddedAction(tracks));
              room.join();
            })
            .catch((error) => {
              dispatch(setVideoEnabled(false));
              Cookies.remove("videoInput");
              dispatch(removeInputDeviceId("videoInput"));
              toast.error(commonMessage.videoDeviceNotAvailable, {
                autoClose: false,
              });
              // throw error;
            });

          //For audio
          try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
          } catch (err) {
            console.log("err-<", err);
          }

          JitsiMeetJS.createLocalTracks({
            devices: ["audio"],
            micDeviceId: audioInputDeviceId || "default",
          })
            .then((tracks) => {
              dispatch(localTracksAddedAction(tracks));
              room.join();
            })
            .catch((error) => {
              //   console.log("***con start error***", error);
              dispatch(setMainMicMute(true));
              console.log("here=>", error);
              Cookies.remove("audioInput");
              dispatch(removeInputDeviceId("audioInput"));
              toast.error(commonMessage.audioDeviceNotAvailable, {
                autoClose: false,
              });
              // throw error;
            });
        });
    } else {
      room.join();
    }

    room.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track) => {
      dispatch(remoteTrackAddedAction(track));
    });
    room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
      // console.log("NAN NAN NAA");
      dispatch(remoteTrackRemoved(track));
    });

    room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
      dispatch(conferenceJoined());
    });
    room.on(JitsiMeetJS.events.conference.USER_JOINED, (id, user) => {
      console.log("********** Joined", id, user);

      dispatch(userJoined({ id, user }));
    });
    room.on(JitsiMeetJS.events.conference.USER_LEFT, (id, user) => {
      dispatch(userLeft({ id, user }));
    });
    room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => {
      console.log(`${track.getType()} - ${track.isMuted()}`);
      dispatch(remoteTrackMuteChaned(track));
    });
    room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, () => {
      dispatch(setRemoteUserName());
    });

    // room.on(JitsiMeetJS.events.e2eping, (p, r) => {
    //   console.log("p=<>", p, "r=>", r);
    // });

    // room.on(
    //   JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
    //   (stats) => {
    //     console.log("LOCAL STAT=>", stats)
    //     dispatch(changeLocalStats(JSON.parse(JSON.stringify(stats))));
    //   }
    // );

    // room.on(
    //   JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
    //   (id, stats) => {
    //     console.log("remote Stats=>", id, stats);
    //     let obj = { [id]: JSON.parse(JSON.stringify(stats)) };
    //     dispatch(changeAllRemoteStats(obj));
    //   }
    // );
  };

export const setInitialStateAction = () => (dispatch) => {
  dispatch(setInitialState());
};

export const setDevicesAction = (devices) => (dispatch) => {
  dispatch(devicesListUpdated(devices));
};

export const setSelecteduserAction = (userId) => (dispatch) => {
  dispatch(setSelecteduser(userId));

  // When select video preview then show Large Video Tab in sidebar
  dispatch(setSelectedTab(2));
};

export const setSelectedTabAction = (value) => (dispatch) => {
  dispatch(setSelectedTab(value));
};

export const setEventJoinedAction = (value) => (dispatch) => {
  dispatch(setEventJoined(value));
};
export const setEventIdAction = (value) => (dispatch) => {
  dispatch(setEventId(value));
};
export const setParticipantTypeAction = (value) => (dispatch) => {
  dispatch(setParticipantType(value));
};
export const setDisplayNameAction = (value) => (dispatch) => {
  dispatch(setDisplayName(value));
};
export const changeConsoleModeAction = (value) => (dispatch) => {
  dispatch(ChangeConsoleMode(value));
};
export const changeThemeModeAction = (value) => (dispatch) => {
  dispatch(changeThemeMode(value));
};
export const changeChimeEnabledAction = (value) => (dispatch) => {
  dispatch(changeChimeEnabled(value));
};
export const changeBrickLimiterAction = (value) => (dispatch) => {
  dispatch(changeBrickLimiter(value));
};
export const changeShowSettingsAction = (value) => (dispatch) => {
  dispatch(changeShowSettings(value));
};
export const changeShowQuickMessagesAction = (value) => (dispatch) => {
  dispatch(changeShowQuickMessages(value));
};
export const changeDefaultBrickLimiterAction = (value) => (dispatch) => {
  dispatch(changeDefaultBrickLimiter(value));
};
export const changeThresholdValueAction = (value) => (dispatch) => {
  dispatch(changeThresholdValue(value));
};
export const changeAttackValueAction = (value) => (dispatch) => {
  dispatch(changeAttackValue(value));
};
export const changeReleaseValueAction = (value) => (dispatch) => {
  dispatch(changeReleaseValue(value));
};
export const changePregainValueAction = (value) => (dispatch) => {
  dispatch(changePregainValue(value));
};
export const changePostgainValueAction = (value) => (dispatch) => {
  dispatch(changePostgainValue(value));
};
export const setBoothIdAction = (value) => (dispatch) => {
  dispatch(setBoothId(value));
};

export const setParticipantIdAction = (value) => (dispatch) => {
  dispatch(setParticipantId(value));
};

export const localTrackRemovedAction = (type) => (dispatch) => {
  dispatch(localTrackRemoved(type));
};

export const localSingleTrackRemovedAction = (type) => (dispatch) => {
  dispatch(localSingleTrackRemoved(type));
};

export const localSingleTrackAddedAction = (track) => (dispatch) => {
  dispatch(localSingleTrackAdded(track));
};

export const setVideoEnabledAction = (value) => (dispatch) => {
  dispatch(setVideoEnabled(value));
};

export const setAudioEnabledAction = (value) => (dispatch) => {
  dispatch(setAudioEnabled(value));
};
export const setEnableTextChatAction = (value) => (dispatch) => {
  dispatch(setEnableTextChat(value));
};

export const setMainMicMuteAction = (value) => (dispatch) => {
  dispatch(setMainMicMute(value));
};

export const setBoothmatesMuteAction = (value) => (dispatch) => {
  dispatch(setBoothmatesMute(value));
};

export const setBoothmateVolumeAction = (value) => (dispatch) => {
  dispatch(setBoothmateVolume(value));
};

export const setAllAudioForMod = (value) => (dispatch) => {
  dispatch(setAllAudioVolume(value));
};

export const setBoothmateAudioAction = (value) => (dispatch) => {
  dispatch(setBoothmateAudio(value));
};

export const setAudioVideoDeviceIdAction = (value) => (dispatch) => {
  dispatch(setAudioVideoDeviceId(value));
};

const confOptions = {
  p2p: {
    enabled: true,
  },
  // enableNoisyMicDetection : true,
  stereo: false,
  audioQuality: {
    stereo: true,
    opusMaxAverageBitrate: 510000, // Value to fit the 6000 to 510000 range. or null
  },
  e2eping: {
    pingInterval: true,
  },
  // rttMonitor: {
  //   enabled: true
  // }
  // e2ee: {
  //     labels: {
  //         labelTooltip: 'Tooltip',
  //         description: 'Description',
  //         label: 'E2EE',
  //         warning: 'Warning'
  //     },
  //     externallyManagedKey: false
  // }
};

/**
 * Handles local tracks.
 * @param tracks Array with JitsiTrack objects
 */
export const localTracksAddedAction = (tracks) => (dispatch) => {
  dispatch(localTracksAdded(tracks));
};

/**
 * Handles local tracks.
 * @param tracks Array with JitsiTrack objects
 */
export const localTracksReplacedAction = (tracks) => (dispatch) => {
  dispatch(localTracksReplaced(tracks));
};

/**
 * This export function is called when the connection fail.
 */
export function onConnectionFailed(e) {
  console.log("Connection Failed!", e);
}

/**
 * This export function is called when the connection fail.
 */
export function onDeviceListChanged(devices) {
  console.info("current devices", devices);
}

/**
 *
 * @param selected
 */
export function changeAudioOutput(selected) {
  // eslint-t-line no-unused-vars
  JitsiMeetJS.mediaDevices.setAudioOutputDevice(selected.value);
}

export default connectionSlice.reducer;
