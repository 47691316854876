import React, { Fragment, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelecteduserAction } from "../Redux/Reducers/Connection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LocalTrack = ({
  track,
  index,
  isAudioMuted,
  chimeEnabled,
  boothmatesMute,
  JitsiMeetJS,
  room,
}) => {
  console.log("chimeEnabled=>", chimeEnabled);
  const dispatch = useDispatch();
  const { videoEnabled, selecteduser } = useSelector(
    (state) => state.connection
  );

  // Toggle selected user for large video
  const selectUserForLargeVideo = useCallback(() => {
    dispatch(setSelecteduserAction(selecteduser === "local" ? "" : "local"));
  }, [dispatch, selecteduser]);

  // Attach video track to the provided DOM element reference
  const attachVideo = useCallback(
    (videoRef) => {
      if (videoRef) track.attach(videoRef);
    },
    [track]
  );

  // Generate control icon classes based on the conditions
  const renderIcon = (condition, trueClass, falseClass) => {
    // console.log("condiftion=>", condition)
    return condition ? trueClass : falseClass;
  };

  // Generate video controls icons
  const renderVideoControls = () => (
    <div className="video-controls">
      <i
        className={renderIcon(
          chimeEnabled,
          "fa-solid fa-bell-on",
          "fa-solid fa-bell-slash text-danger"
        )}
      ></i>
      <i
        className={renderIcon(
          boothmatesMute,
          "fa-solid fa-ear-deaf text-danger",
          "fa-solid fa-ear"
        )}
      ></i>
      <i
        className={renderIcon(
          isAudioMuted === undefined || isAudioMuted,
          "fa-solid fa-microphone-slash",
          "fa-solid fa-microphone text-danger"
        )}
      ></i>
    </div>
  );

  if (track.getType() === "video") {
    // If video is disabled, show placeholder image
    if (!videoEnabled) {
      return (
        <Fragment key={index}>
          <LazyLoadImage
            className="video-thumb img-fluid noImage-thumb"
            id={`localVideo${index}`}
            alt="Video not available"
            src="/media/noPicture.svg"
            effect="blur"
          />
          {/* Optionally, add track stats if needed */}
          {/* <LocalTrackStats JitsiMeetJS={JitsiMeetJS} room={room} /> */}
          {renderVideoControls()}
        </Fragment>
      );
    }

    // Render the video element when video is enabled
    return (
      <Fragment key={index}>
        <video
          className="video-thumb img-fluid"
          id={`localVideo${index}`}
          onClick={selectUserForLargeVideo}
          ref={attachVideo}
          autoPlay
          poster="/media/black-screen.svg"
        />
        {/* Optionally, add track stats if needed */}
        {/* <LocalTrackStats JitsiMeetJS={JitsiMeetJS} room={room} /> */}
        {renderVideoControls()}
      </Fragment>
    );
  }

  // If track type is not video, return empty fragment
  return <Fragment key={index}></Fragment>;
};

// Memoize component to prevent unnecessary re-renders
const compareProps = (prevProps, nextProps) => {
  return prevProps.track.isMuted() !== nextProps.track.isMuted();
};

export default memo(LocalTrack, compareProps);
