import { useCallback, memo } from "react";
import { useSelector } from "react-redux";

function LargeVideo({ track }) {
  const { remoteTracks, selecteduser, videoEnabled } = useSelector(
    (state) => state.connection
  );

  // Memoize the video attachment function for performance
  const attachVideo = useCallback(
    (refData) => {
      if (refData && track) {
        track.attach(refData);
      }
    },
    [track]
  );

  // Check conditions to determine whether to show a message or the video
  const shouldShowMessage =
    !selecteduser ||
    (selecteduser === "local" && !videoEnabled) ||
    (selecteduser !== "local" &&
      (!remoteTracks[selecteduser] || track.isMuted()));

  if (shouldShowMessage) {
    return (
      <div id="video-viewer-instruction" className="font-italic text-muted p-3">
        View a larger version of an interpreter's video by clicking their
        thumbnail.
      </div>
    );
  }

  return (
    <video
      className="video-thumb img-fluid largevideo"
      autoPlay
      ref={attachVideo}
      id="localVideo-largevideo"
      preload="auto"
      poster="/media/black-screen.svg"
    />
  );
}

export default memo(LargeVideo);

// import { useCallback, memo } from "react";
// import { useSelector } from "react-redux";

// function LargeVideo({ track }) {
//   const { remoteTracks, selecteduser, videoEnabled } = useSelector(
//     (state) => state.connection
//   );

//   const attachVideo = useCallback(
//     (refData) => {
//       refData && track && track.attach(refData);
//     },
//     [track]
//   );

//   if (
//     !selecteduser ||
//     (selecteduser === "local" && !videoEnabled) ||
//     (selecteduser !== "local" &&
//       (!remoteTracks[selecteduser] || track.isMuted()))
//   ) {
//     return (
//       <div
//         id="video-viewer-instruction"
//         className="font-italic text-muted p-3"
//         style={{ display: "block" }}
//       >
//         View a larger version of an interpreter's video by clicking their
//         thumbnail.
//       </div>
//     );
//   }

//   return (
//     <>
//       <video
//         className="video-thumb img-fluid largevideo"
//         autoPlay="1"
//         ref={attachVideo}
//         id={`localVideo-largevideo`}
//         preload={"auto"}
//         poster={`/media/black-screen.svg`}
//       />
//     </>
//   );
// }

// // function compare(preProps, nextProps) {
// //     return (preProps.track.isMuted() !== nextProps.track.isMuted());
// // }

// // export default memo(LargeVideo, compare);
// export default memo(LargeVideo);
// // export default LargeVideo;
