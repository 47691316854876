import Chat from "../../components/Chat";
// import RemoteParticipantsJoinedListing from "../../components/RemoteParticipantsJoinedListing";
import SimpleRemoteVideos from "../../components/SimpliedFiedComponents/remoteVideos";
import { useSelector } from "react-redux";
import MainAudioControl from "../../components/MainAudioControl";
import { MuteControls } from "../../components/SimpliedFiedComponents/muteControls";

function SimpleInterpreterConsole({
  eventDetail,
  playChime,
  chimeEnabled,
  JitsiMeetJS,
  room,
  audioEnabled,
  boothName,
}) {
  console.log("boothName=>", boothName);
  return (
    <>
      <div className="simple-intep-wrapper">
        <div className="row">
          <div className="col-12 col-md-6 cstm-col">
            <div className="card">
              <div className="card-body px-2 booth-details ">
                <div className="booth-detail-wrapper">
                  <div className="booth-detail-left">
                    <h3>
                      <span id="booth-name">{boothName}</span> Booth
                    </h3>
                  </div>
                  <div className="booth-detail-right">
                    <MuteControls eventDetail={eventDetail} />
                  </div>
                </div>
              </div>
            </div>
            <SimpleRemoteVideos JitsiMeetJS={JitsiMeetJS} room={room} />
            <div className="simple-intrep-audio-controls">
              {audioEnabled && <MainAudioControl />}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <Chat
              eventDetail={eventDetail}
              callAlert={playChime}
              chimeEnabled={chimeEnabled}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SimpleInterpreterConsole;
