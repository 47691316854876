import {
  useCallback,
  Fragment,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import LocalTracks from "./LocalTracks";
import RemoteTrack from "./RemoteTrack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const RemoteParticipantsJoinedListing = ({ JitsiMeetJS, room }) => {
  const videoRef = useRef(null);
  const [showLVideoMsg, setShowLVideoMsg] = useState(true);

  // Redux state selectors
  const {
    localTracks,
    remoteTracksArray,
    displayName,
    joinedUsers,
    selecteduser: selectedUser,
  } = useSelector((state) => state.connection);

  // Hide the large video message if localTracks are available and a user is selected
  useEffect(() => {
    if (localTracks.length && selectedUser) {
      setShowLVideoMsg(false);
    }
  }, [localTracks, selectedUser]);

  //waiting loader
  const WaitingLoader = () => (
    <div className="p-1 text-center loading-txt">
      Please wait, while connecting
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  );

  // Memoize breakpoints
  const swiperBreakpoints = useMemo(
    () => ({
      0: { slidesPerView: 1, spaceBetween: 10 },
      320: { slidesPerView: 1, spaceBetween: 10 },
      500: { slidesPerView: 2, spaceBetween: 10 },
      767: { slidesPerView: 1, spaceBetween: 10 },
      970: { slidesPerView: 2, spaceBetween: 10 },
      1332: { slidesPerView: 3, spaceBetween: 10 },
      1810: { slidesPerView: 3, spaceBetween: 20 },
      2560: { slidesPerView: 4, spaceBetween: 10 },
    }),
    []
  );

  // Function to render audio and video tracks
  const renderAudioVideo = useCallback(
    (id, remoteUserData = null) => {
      const existingTrack = remoteTracksArray.find((item) => item.id === id);

      if (!existingTrack || existingTrack.tracks.length === 0) {
        return (
          <LazyLoadImage
            className="video-thumb noImage-thumb img-fluid"
            id={`${id}-video-thumb-0001`}
            alt="No video"
            src="/media/noPicture.svg"
            effect="blur"
          />
        );
      }

      let isVideoExist = existingTrack.tracks.filter(
        (track) => track.getType() === "video"
      );
      let audioTrackExist = existingTrack.tracks.find(
        (track) => track.getType() === "audio"
      );

      return existingTrack.tracks.map((track, index) => (
        <RemoteTrack
          key={track.getId()}
          index={index}
          id={id}
          isAudioMuted={audioTrackExist?.isMuted()}
          track={track}
          isVideoExist={isVideoExist}
          remoteUserData={remoteUserData}
          JitsiMeetJS={JitsiMeetJS}
          room={room}
        />
      ));
    },
    [remoteTracksArray, JitsiMeetJS, room]
  );

  // Function to render the remote users' tracks
  const renderRemoteTrack = useCallback(() => {
    return Object.keys(joinedUsers).map((id, index) => {
      const user = joinedUsers[id];
      if (user && user._properties?.participant_type === "interpreter") {
        return (
          <SwiperSlide className="video-col" key={index}>
            <div
              className="video-col-bx text-center"
              style={
                selectedUser === id
                  ? { border: "1px solid #007bff", borderRadius: "18px" }
                  : {}
              }
            >
              <div className="video-render">{renderAudioVideo(id, user)}</div>
              <div className="content-video-sec">
                <div className="userName">{user._displayName}</div>
              </div>
            </div>
          </SwiperSlide>
        );
      }
      return <Fragment key={id} />;
    });
  }, [joinedUsers, renderAudioVideo, selectedUser]);

  return (
    <div className="card">
      <div className="card-body videos-card-body">
        {localTracks.length && showLVideoMsg ? (
          <div className="largeVideoMessage">
            View a larger version of an interpreter's video by clicking their
            thumbnail.
          </div>
        ) : null}

        {localTracks.length == 0 && <WaitingLoader />}

        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="video-row"
          breakpoints={swiperBreakpoints}
          ref={videoRef}
        >
          <SwiperSlide className="video-col">
            {localTracks.length > 0 && (
              <div
                className="video-col-bx text-center"
                style={
                  selectedUser === "local"
                    ? { border: "1px solid #007bff", borderRadius: "18px" }
                    : {}
                }
              >
                <div className="video-render">
                  <LocalTracks JitsiMeetJS={JitsiMeetJS} room={room} />
                </div>
                <div className="content-video-sec">
                  <div className="userName">{displayName}</div>
                  <span>(Me)</span>
                </div>
              </div>
            )}
          </SwiperSlide>
          {localTracks.length > 0 && renderRemoteTrack()}
        </Swiper>
      </div>
    </div>
  );
};

export default RemoteParticipantsJoinedListing;
