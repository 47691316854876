/* global JitsiMeetJS */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setBoothIdAction,
  setDevicesAction,
  localTracksReplacedAction,
  localSingleTrackAddedAction,
  setAudioVideoDeviceIdAction,
  setDisplayNameAction,
} from "../Redux/Reducers/Connection";
import { capitalizeFirstLetter } from "../helpers/common";
import {
  getEventDetails,
  interpreterUpdateService,
  clientUpdateService,
  moderatorUpdateService,
} from "../Services/interceptorServices";
import { toast } from "react-toastify";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useMutation } from "@tanstack/react-query";

const JoinedParticipantControl = (props) => {
  const {
    participantId,
    event_id,
    booth_language_id,
    displayName,
    devices,
    videoEnabled,
    videoInputDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
    participant_type,
  } = useSelector((state) => state.connection);

  const dispatch = useDispatch();
  const [boothList, setBoothList] = useState([]);
  const [localName, setLocalName] = useState(displayName);

  const nameChangeMutation = useMutation({
    mutationFn: async (customData) => customData.customAPI(customData.obj),
    onError: (error) => {
      console.error("Error:", error);
      toast.error(error.message);
    },
  });

  useEffect(() => {
    const initializeDevices = async () => {
      if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output")) {
        try {
          await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          });
        } catch (error) {
          console.error("Error:", error);
        }

        JitsiMeetJS.mediaDevices.enumerateDevices((deviceList) => {
          dispatch(setDevicesAction(deviceList));
        });

        if (audioOutputDeviceId && audioOutputDeviceId !== "default") {
          JitsiMeetJS.mediaDevices.setAudioOutputDevice(audioOutputDeviceId);
        }

        if (audioInputDeviceId && audioInputDeviceId !== "default") {
          updateAudioInputDevice(audioInputDeviceId);
        }
      }
    };

    initializeDevices();
  }, [audioInputDeviceId, audioOutputDeviceId, dispatch]);

  useEffect(() => {
    setLocalName(displayName);
  }, [displayName]);

  useEffect(() => {
    if (event_id) {
      getEventDetails(event_id).then((response) => {
        const data = response?.data;
        if (data?.code === 200 && data?.data) {
          setBoothList(data.data.languages);
        } else {
          setBoothList([]);
        }
      });
    }
  }, [event_id]);

  const renderDeviceOptions = (type) => {
    const audioOutputDevices = devices.filter(
      (device) => device.kind === "audiooutput"
    );
    const audioInputDevices = devices.filter(
      (device) => device.kind === "audioinput"
    );
    const videoInputDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );

    const getOptions = (deviceList, selectedDeviceId) =>
      deviceList.map((device, index) => (
        <option
          key={index}
          value={device.deviceId}
          selected={selectedDeviceId === device.deviceId}
        >
          {device.label}
        </option>
      ));

    switch (type) {
      case "audioInput":
        return getOptions(audioInputDevices, audioInputDeviceId);
      case "audioOutput":
        return audioOutputDevices.length > 0 ? (
          getOptions(audioOutputDevices, audioOutputDeviceId)
        ) : (
          <option value={JitsiMeetJS.mediaDevices.getAudioOutputDevice()}>
            {capitalizeFirstLetter(
              JitsiMeetJS.mediaDevices.getAudioOutputDevice()
            )}{" "}
            Speaker
          </option>
        );
      case "videoInput":
        return getOptions(videoInputDevices, videoInputDeviceId);
      default:
        return null;
    }
  };

  const updateAudioInputDevice = (deviceId) => {
    dispatch(setAudioVideoDeviceIdAction({ type: "audioinput", deviceId }));
    JitsiMeetJS.createLocalTracks({ devices: ["audio"], micDeviceId: deviceId })
      .then((tracks) => {
        if (tracks.length) {
          dispatch(
            localSingleTrackAddedAction({ track: tracks[0], type: "audio" })
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateVideoInputDevice = (deviceId) => {
    if (!videoEnabled) return;

    dispatch(setAudioVideoDeviceIdAction({ type: "videoinput", deviceId }));
    JitsiMeetJS.createLocalTracks({
      devices: ["video"],
      cameraDeviceId: deviceId,
    })
      .then((tracks) => {
        if (tracks.length) {
          dispatch(localTracksReplacedAction(tracks));
        }
      })
      .catch((error) => {
        toast.error(
          "Selected camera unavailable. Please select a different camera.",
          { autoClose: false }
        );
        console.error("Error:", error);
      });
  };

  const handleNameChange = (event) => {
    if (event.key === "Enter") {
      const toastId = toast.loading("Please wait...");
      const participantName = event.target.value;
      const updatePayload = {
        participant_id: participantId,
        name: participantName,
      };
      let customAPI = "";

      if (participant_type === "client") {
        customAPI = clientUpdateService;
      } else if (participant_type === "moderator") {
        customAPI = moderatorUpdateService;
      } else {
        customAPI = interpreterUpdateService;
        updatePayload.booth_language_id = booth_language_id;
      }

      nameChangeMutation.mutate(
        { customAPI, obj: updatePayload },
        {
          onSuccess: (response) => {
            const { code, errors } = response?.data || {};
            if (code === 200) {
              dispatch(setDisplayNameAction(participantName));
              toast.update(toastId, {
                render: "Name changed successfully!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
              });
              props.refresh();
            } else {
              const errorMessage = errors?.[0] || "Something went wrong";
              toast.update(toastId, {
                render: errorMessage,
                type: "error",
                isLoading: false,
                autoClose: 1500,
              });
            }
          },
        }
      );
    }
  };

  const handleBoothChange = (boothId) => {
    interpreterUpdateService({
      participant_id: participantId,
      booth_language_id: boothId,
    })
      .then((response) => {
        const { code, errors } = response?.data || {};
        if (code === 200) {
          dispatch(setBoothIdAction(boothId));
          localStorage.removeItem("currentTime");
          localStorage.removeItem("selectedMinutes");
          setTimeout(() => window.location.reload(), 100);
        } else {
          toast.error(errors?.[0] || "Something went wrong!");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        console.error("Error:", error);
      });
  };

  const boothOptions = () =>
    Object.keys(boothList).map((id, index) => (
      <option key={index} value={id}>
        {boothList[id]}
      </option>
    ));

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div
            className={`col-12 ${
              participant_type === "interpreter" ? "col-lg-6" : ""
            }`}
          >
            <div className="form-group">
              <label>Your Name</label>
              <input
                className="form-control border p-2 bg-light border-dark"
                type="text"
                value={localName}
                onKeyDown={handleNameChange}
                onChange={(e) => setLocalName(e.target.value)}
                autoComplete="off"
              />
            </div>
          </div>

          {participant_type === "interpreter" && (
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label>Booth</label>
                <select
                  className="custom-select bg-light border border-dark"
                  value={booth_language_id}
                  onChange={(e) => handleBoothChange(e.target.value)}
                >
                  {boothOptions()}
                </select>
              </div>
            </div>
          )}
        </div>

        <div className="row">
          {participant_type === "interpreter" && (
            <>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label>Listen Through</label>
                  <select
                    className="custom-select bg-light border border-dark"
                    value={audioOutputDeviceId}
                    onChange={(e) => {
                      JitsiMeetJS.mediaDevices.setAudioOutputDevice(
                        e.target.value
                      );
                      dispatch(
                        setAudioVideoDeviceIdAction({
                          type: "audiooutput",
                          deviceId: e.target.value,
                        })
                      );
                    }}
                  >
                    {renderDeviceOptions("audioOutput")}
                  </select>
                  <div className="small text-muted">
                    Leave as Default if unsure
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label>Speak Through</label>
                  <select
                    className="custom-select bg-light border border-dark"
                    value={audioInputDeviceId}
                    onChange={(e) => updateAudioInputDevice(e.target.value)}
                  >
                    {renderDeviceOptions("audioInput")}
                  </select>
                  <div className="small text-muted">
                    Leave as Default if unsure
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="col-12">
            <div className="form-group">
              <label>Video Camera</label>
              <select
                className="custom-select bg-light border border-dark"
                value={videoInputDeviceId}
                onChange={(e) => updateVideoInputDevice(e.target.value)}
              >
                {renderDeviceOptions("videoInput")}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinedParticipantControl;

// /* global JitsiMeetJS */
// import { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   setBoothIdAction,
//   setDevicesAction,
//   localTracksReplacedAction,
//   localSingleTrackAddedAction,
//   setAudioVideoDeviceIdAction,
//   setDisplayNameAction,
// } from "../Redux/Reducers/Connection";
// import { capitalizeFirstLetter } from "../helpers/common";
// import {
//   getEventDetails,
//   interpreterUpdateService,
//   clientUpdateService,
//   moderatorUpdateService,
// } from "../Services/interceptorServices";
// import { toast } from "react-toastify";
// import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
// import { useMutation } from "@tanstack/react-query";

// const JoinedParticipantControll = (props) => {
//   const {
//     participantId,
//     event_id,
//     booth_language_id,
//     displayName,
//     devices,
//     videoEnabled,
//     videoInputDeviceId,
//     audioInputDeviceId,
//     audioOutputDeviceId,
//     participant_type,
//   } = useSelector((state) => state.connection);

//   const dispatch = useDispatch();
//   const [boothList, setBoothList] = useState([]);
//   const [localName, setLocalName] = useState(displayName);

//   const nameChangeMutation = useMutation({
//     mutationFn: async (customData) => {
//       return await customData.customAPI(customData.obj);
//     },
//     onError: (err) => {
//       console.log("err=>", err);
//       toast.error(err.message);
//     },
//   });

//   useEffect(() => {
//     (async () => {
//       if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output")) {
//         try {
//           await navigator.mediaDevices.getUserMedia({
//             audio: true,
//             video: true,
//           });
//         } catch (err) {
//           console.log("err=>", err);
//         }

//         JitsiMeetJS.mediaDevices.enumerateDevices((devicesData) => {
//           dispatch(setDevicesAction(devicesData));
//         });
//         if (audioOutputDeviceId && audioOutputDeviceId !== "default") {
//           JitsiMeetJS.mediaDevices.setAudioOutputDevice(audioOutputDeviceId);
//         }
//         if (audioInputDeviceId && audioInputDeviceId !== "default") {
//           changeAudioInput(audioInputDeviceId);
//         }
//       }
//     })();
//   }, []);

//   useEffect(() => {
//     setLocalName(displayName);
//   }, [displayName]);

//   useEffect(() => {
//     if (event_id) {
//       getEventDetails(event_id).then((resp) => {
//         if (resp && resp.data && resp.data.code === 200 && resp.data.data) {
//           setBoothList(resp.data.data.languages);
//         } else {
//           setBoothList([]);
//         }
//       });
//     }
//   }, [event_id]);

//   const renderDeviesList = (type = "audioInput") => {
//     const audioOutputDevices = devices.filter((d) => d.kind === "audiooutput");
//     const audioInputDevices = devices.filter((d) => d.kind === "audioinput");
//     const videoInputDevices = devices.filter((d) => d.kind === "videoinput");

//     if (type === "audioInput") {
//       return audioInputDevices.map((d, index) => {
//         let extraProps = {};
//         if (audioInputDeviceId === d.deviceId) {
//           extraProps.defaultValue = "selected";
//         }
//         return (
//           <option key={index} {...extraProps} value={d.deviceId}>
//             {d.label}
//           </option>
//         );
//       });
//     }
//     if (type === "audioOutput") {
//       if (audioOutputDevices.length === 0) {
//         let odevice = JitsiMeetJS.mediaDevices.getAudioOutputDevice();
//         return (
//           <option value={odevice}>
//             {capitalizeFirstLetter(odevice)} Speaker
//           </option>
//         );
//       }
//       return audioOutputDevices.map((d, index) => {
//         let extraProps = {};
//         if (audioOutputDeviceId === d.deviceId) {
//           extraProps.defaultValue = "selected";
//         }
//         return (
//           <option key={index} {...extraProps} value={d.deviceId}>
//             {d.label}
//           </option>
//         );
//       });
//     }
//     if (type === "videoInput") {
//       return videoInputDevices.map((d, index) => {
//         let extraProps = {};
//         if (videoInputDeviceId === d.deviceId) {
//           extraProps.defaultValue = "selected";
//         }
//         return (
//           <option key={index} {...extraProps} value={d.deviceId}>
//             {d.label}
//           </option>
//         );
//       });
//     }
//   };

//   const changeAudioInput = (deviceId) => {
//     // dispatch(localSingleTrackRemovedAction("audio"));
//     dispatch(
//       setAudioVideoDeviceIdAction({
//         type: "audioinput",
//         deviceId: deviceId,
//       })
//     );

//     JitsiMeetJS.createLocalTracks({ devices: ["audio"], micDeviceId: deviceId })
//       .then((tracks) => {
//         if (tracks && tracks.length) {
//           dispatch(
//             localSingleTrackAddedAction({ track: tracks[0], type: "audio" })
//           );
//         }
//       })
//       .catch((error) => {
//         throw error;
//       });
//   };

//   const changeVideoInput = (deviceId) => {
//     if (!videoEnabled) {
//       return false;
//     }
//     // dispatch(localTrackRemovedAction("video"));
//     dispatch(
//       setAudioVideoDeviceIdAction({
//         type: "videoinput",
//         deviceId: deviceId,
//       })
//     );

//     JitsiMeetJS.createLocalTracks({
//       devices: ["video", "audio"],
//       cameraDeviceId: deviceId,
//     })
//       .then((tracks) => {
//         if (tracks && tracks.length) {
//           dispatch(localTracksReplacedAction(tracks));
//         }
//       })
//       .catch((error) => {
//         toast.error(
//           "The camera you selected is not available. It may be used by another application. Please select a different camera.",
//           { autoClose: false }
//         );
//         throw error;
//       });
//   };

//   let videoProps = {};
//   if (!videoEnabled) {
//     videoProps.disabled = "disabled";
//   }

//   const getBooths = () => {
//     let sortedLanguages = Object.values(boothList)
//       .map((value) => value)
//       .sort();
//     return sortedLanguages.map((booth, index) => {
//       let id = Object.keys(boothList).find((key) => {
//         return boothList[key] === booth;
//       });
//       return (
//         <option key={index} value={id}>
//           {boothList[id]}
//         </option>
//       );
//     });
//   };

//   const onKeyDown = (event) => {
//     // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
//     if (event.key === "Enter") {
//       const toastId = toast.loading("Please wait...");
//       event.preventDefault();
//       event.stopPropagation();
//       let obj = { participant_id: participantId, name: event.target.value };
//       let customAPI = "";
//       if (participant_type === "client") {
//         customAPI = clientUpdateService;
//       } else if (participant_type === "moderator") {
//         customAPI = moderatorUpdateService;
//       } else {
//         customAPI = interpreterUpdateService;
//         obj = { ...obj, booth_language_id: booth_language_id };
//       }

//       nameChangeMutation.mutate(
//         {
//           customAPI,
//           obj,
//         },
//         {
//           onSuccess: (resp) => {
//             if (resp && resp.data && resp.data.code === 200) {
//               dispatch(setDisplayNameAction(event.target.value));
//               // toast.success("Name changed successfully!");
//               toast.update(toastId, {
//                 render: "Name changed successfully!",
//                 type: "success",
//                 isLoading: false,
//                 closeButton: true,
//                 autoClose: 1500,
//               });
//               props.refresh();
//             } else if (
//               resp.data &&
//               resp.data.errors &&
//               resp.data.errors.length
//             ) {
//               toast.update(toastId, {
//                 render: resp.data.errors[0],
//                 type: "error",
//                 isLoading: false,
//                 closeButton: true,
//                 autoClose: 1500,
//               });
//             } else {
//               toast.update(toastId, {
//                 render: "something went wrong",
//                 type: "error",
//                 isLoading: false,
//                 closeButton: true,
//                 autoClose: 1500,
//               });
//             }
//           },
//         }
//       );
//     }
//   };

//   const changeBoothAction = (boothId) => {
//     interpreterUpdateService({
//       participant_id: participantId,
//       booth_language_id: boothId,
//     })
//       .then((resp) => {
//         // console.log("***changeParticipantboothId***", resp.data);
//         if (resp && resp.data && resp.data.code === 200) {
//           // toast.success("Booth changed successfully!");

//           dispatch(setBoothIdAction(boothId));
//           localStorage.removeItem("currentTime");
//           localStorage.removeItem("selectedMinutes");
//           setTimeout(() => window.location.reload(), 100);
//         } else if (resp && resp.data && resp.data.code === 400) {
//           toast.error(`${resp.data?.errors[0] || "Something went wrong!"}`);
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((err) => {
//         toast.error("Something went wrong!");
//         console.log("***err***", err);
//       });
//   };

//   // const changeBoothmatesMute = () => {
//   //   dispatch(setBoothmatesMuteAction(!boothmatesMute));
//   // };

//   return (
//     // participant_type !== "moderator" && (
//     <div className="card">
//       <div className="card-body">
//         <div className="row">
//           {/* {participant_type !== "moderator" && ( */}
//           <div
//             className={`col-12 ${
//               participant_type !== "interpreter" ? " " : "col-lg-6"
//             }`}
//           >
//             <div className="form-group">
//               <label>Your Name</label>
//               <input
//                 className="form-control border p-2 bg-light border-dark "
//                 type={"text"}
//                 name="name"
//                 value={localName}
//                 onKeyDown={onKeyDown}
//                 onChange={(event) => {
//                   setLocalName(event.target.value);
//                 }}
//                 autoComplete="off"
//               />
//               {/* <div className="bg-light border p-2 font-weight-bold text-success" id={"displayName"}>{displayName}</div> */}
//             </div>
//           </div>
//           {/* )} */}
//           {participant_type !== "moderator" &&
//             participant_type === "interpreter" && (
//               <div className="col-12 col-lg-6">
//                 <div className="form-group">
//                   <label>Booth</label>
//                   <select
//                     defaultValue={booth_language_id}
//                     name="booth_language_id"
//                     id="booth-language-id"
//                     className="custom-select bg-light border border-dark"
//                     value={booth_language_id}
//                     onChange={(e) => {
//                       changeBoothAction(e.target.value);
//                     }}
//                   >
//                     {getBooths()}
//                   </select>
//                 </div>
//               </div>
//             )}
//         </div>
//         <div className="row">
//           {participant_type === "interpreter" && (
//             <div className="col-12 col-lg-6">
//               <div className="form-group">
//                 <label>
//                   {participant_type === "moderator"
//                     ? "Device To Listen Through"
//                     : "Listen Through"}
//                 </label>
//                 <select
//                   name="device"
//                   id="audioOutputSelect"
//                   onChange={(e) => {
//                     JitsiMeetJS.mediaDevices.setAudioOutputDevice(
//                       e.target.value
//                     );
//                     dispatch(
//                       setAudioVideoDeviceIdAction({
//                         type: "audiooutput",
//                         deviceId: e.target.value,
//                       })
//                     );
//                   }}
//                   className="custom-select bg-light border border-dark"
//                   defaultValue={audioOutputDeviceId}
//                   value={audioOutputDeviceId}
//                 >
//                   {/* <option value="">Choose...</option> */}
//                   {renderDeviesList("audioOutput")}
//                 </select>

//                 {participant_type !== "moderator" && (
//                   <div className="small text-muted">
//                     Leave as Default if unsure
//                   </div>
//                 )}
//               </div>
//             </div>
//           )}
//           {participant_type === "interpreter" && (
//             <div className="col-12 col-lg-6">
//               <div className="form-group">
//                 <label>Microphone</label>
//                 <select
//                   name="micdevice"
//                   id={"audioInputSelect"}
//                   onChange={(e) => changeAudioInput(e.target.value)}
//                   className="custom-select bg-light border border-dark"
//                   defaultValue={audioInputDeviceId}
//                   value={audioInputDeviceId}
//                 >
//                   {/* <option value="">Choose...</option> */}
//                   {renderDeviesList("audioInput")}
//                 </select>
//                 <div className="small text-muted ">
//                   Leave as Default if unsure
//                 </div>
//               </div>
//             </div>
//           )}
//           {participant_type === "interpreter" && (
//             <div className="col-12 col-lg-6">
//               <div className="form-group">
//                 <label>Camera</label>
//                 <select
//                   {...videoProps}
//                   defaultValue={videoInputDeviceId}
//                   value={videoInputDeviceId}
//                   id={"videoInputSelect"}
//                   onChange={(e) => changeVideoInput(e.target.value)}
//                   name="cameradevice"
//                   className={`custom-select ${
//                     videoProps.disabled ? "bg-secondary text-white" : "bg-light"
//                   } border border-dark`}
//                 >
//                   {/* <option value="">Choose...</option> */}
//                   {renderDeviesList("videoInput")}
//                 </select>
//                 <div className="small text-muted">
//                   Leave as Default if unsure
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     // )
//   );
// };

// export default JoinedParticipantControll;
