import { memo } from "react";

const COLOR_THRESHOLDS = {
  1: { minValue: 1, color: "green" },
  2: { minValue: 15, color: "green" },
  3: { minValue: 30, color: "green" },
  4: { minValue: 45, color: "green" },
  5: { minValue: 60, color: "yellow" },
  6: { minValue: 70, color: "yellow" },
  7: { minValue: 80, color: "red" },
  8: { minValue: 90, color: "red" },
};

const getColor = (value, type, subtype = 0) => {
  const threshold = COLOR_THRESHOLDS[subtype];

  if (threshold && value >= threshold.minValue) {
    return threshold.color;
  }

  return ""; // Return an empty string if no conditions are met
};

// const getColor = (value, type, subtype = 0) => {
//   if (value >= 1 && subtype === 1) {
//     return "green";
//   }
//   if (value > 15 && subtype === 2) {
//     return "green";
//   }
//   if (value > 30 && subtype === 3) {
//     return "green";
//   }
//   if (value > 45 && subtype === 4) {
//     return "green";
//   }

//   if (value > 60 && subtype === 5) {
//     return "yellow";
//   }
//   if (value > 70 && subtype === 6) {
//     return "yellow";
//   }

//   if (value > 80 && subtype === 7) {
//     return "red";
//   }
//   if (value > 90 && subtype === 8) {
//     return "red";
//   }

//   return "";
// };

const Meter = ({ value }) => {
  return (
    <div className="pids-wrapper">
      <div className={`pid ${getColor(value, 1, 1)}`}></div>                     
      <div className={`pid ${getColor(value, 1, 1)}`}></div>
      <div className={`pid ${getColor(value, 1, 2)}`}></div>
      <div className={`pid ${getColor(value, 1, 2)}`}></div>
      <div className={`pid ${getColor(value, 1, 3)}`}></div>
      <div className={`pid ${getColor(value, 1, 3)}`}></div>
      <div className={`pid ${getColor(value, 1, 4)}`}></div>
      <div className={`pid ${getColor(value, 2, 5)}`}></div>
      <div className={`pid ${getColor(value, 2, 5)}`}></div>
      <div className={`pid ${getColor(value, 2, 6)}`}></div>
      <div className={`pid ${getColor(value, 2, 6)}`}></div>
      <div className={`pid ${getColor(value, 3, 7)}`}></div>
      <div className={`pid ${getColor(value, 3, 7)}`}></div>
      <div className={`pid ${getColor(value, 3, 8)}`}></div>
    </div>
  );
};

export default memo(Meter);
